import { Footer, Header } from "components";
import { Web3Context } from "context/Web3Context";
import { About, Mint, Faq, Lander, Terms } from "pages";
import React, { useContext, useState } from "react";
import {
    createBrowserRouter,
    RouterProvider,
    Outlet,
    useLocation,
} from "react-router-dom";

function App() {
    const web3Context = useContext(Web3Context);
    const [web3ContextState, setWeb3ContextState] = useState(web3Context);

    function Layout() {
        const location = useLocation();
        return (
            <div>
                <Header />
                <Outlet />
                {location.pathname !== "/" && (
                    <div className="flex justify-center p-4">
                        <Footer />
                    </div>
                )}
            </div>
        );
    }

    const router = createBrowserRouter([
        {
            element: <Layout />,
            children: [
                {
                    path: "/",
                    element: <Lander />,
                },
                {
                    path: "/mint",
                    element: <Mint />,
                },
                {
                    path: "/about",
                    element: <About />,
                },
                {
                    path: "/tos",
                    element: <Terms />,
                },
                {
                    path: "/faq",
                    element: <Faq />,
                },
            ],
        },
    ]);

    return (
        <div className="App">
            <Web3Context.Provider value={{state: web3ContextState, setState: setWeb3ContextState}}>
                <RouterProvider router={router} />
            </Web3Context.Provider>
        </div>
    );
}

export default App;
