import { Button } from "components";
import { Web3Context } from "context/Web3Context";
import { useContext, useState } from "react";

export function Mint() {
    const web3Context = useContext(Web3Context);

    const [waiting, setWaiting] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);

    const fakeCall = async () => {
        return new Promise((res) => {
            setTimeout(() => {
                res({ error: false, data: {} });
            }, 5000);
        });
    };

    const mintClick = async () => {
        setError(false);
        setSuccess(false);
        setWaiting(true);
        const res = await fakeCall();

        if (res.error) {
            setWaiting(false);
            return setError("There was an error: " + res.error);
        }

        setWaiting(false);
        setSuccess(true);
    };

    return (
        <div className="flex flex-col justify-center relative bg-red-50 min-h-[70vh]">
            <div className="flex justify-center mt-8">
                {web3Context.state.connected
                    ? "You are connected"
                    : "Connect in the top right to mint a R3MI"}
            </div>
            <div className="flex justify-center mt-8">
                <Button
                    primary
                    fancy
                    disabled={!web3Context.state.connected || waiting}
                    onClick={mintClick}
                >
                    {waiting ? (
                        <div className="flex">
                            <div className="mr-2">Please Wait...</div>
                            <div className="relative h-5 w-5">
                                <svg
                                    className="animate-spin h-full w-full text-blue-600 dark:text-blue-500"
                                    viewBox="0 0 24 24"
                                >
                                    <circle
                                        className="opacity-25"
                                        cx="12"
                                        cy="12"
                                        r="10"
                                        stroke="currentColor"
                                        strokeWidth="4"
                                        fill="none"
                                    ></circle>
                                    <path
                                        className="opacity-75"
                                        fill="currentColor"
                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                    ></path>
                                </svg>
                            </div>
                        </div>
                    ) : (
                        <>Mint A R3M1</>
                    )}
                </Button>
            </div>
            <div className="flex justify-center mt-8">
                {web3Context.state.connected ? (
                    <div className="flex-col justify-center">
                        Clicking above will mint a R3MI to:{" "}
                        <span className="text-sm text-remiCharcoal">
                            {web3Context.state.address}
                        </span>
                    </div>
                ) : (
                    ""
                )}
            </div>
            <div className="min-h-8">
                {error && (
                    <div className="flex justify-center mt-4 text-red-500 text-sm">
                        {error} -- Please try again
                    </div>
                )}
                {success && (
                    <div className="flex justify-center mt-4 text-green-500 text-sm">
                        {" "}
                        Your R3MI was successfully minted!
                    </div>
                )}
            </div>
        </div>
    );
}
