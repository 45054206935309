import { createContext } from "react";
import { ethers } from "ethers";

// Get Browser Provider if available

let provider, signer;

if (window.ethereum == null) {
    console.warn("Web3 Not Available, using read-only defaults");
    provider = ethers.getDefaultProvider();
    signer = null;
} else {
    provider = new ethers.BrowserProvider(window.ethereum);
}

export const web3DefaultState = {
    web3Available: !!window.ethereum,
    connected: false,
    address: "0x0",
};

export const Web3Context = createContext(web3DefaultState);

export const setWeb3Connected = (context, isConnected, address) => {
    context.setState((state) => ({
        ...state,
        connected: isConnected,
        address: address
    }));
};

export const attemptConnectWeb3 = async (web3Context) => {
    try {
        signer = await provider.getSigner();
        setWeb3Connected(web3Context, true, signer.address)
        return true;
    } catch (ex) {
        setWeb3Connected(web3Context, false, "0x0")
        return false;
    }
}