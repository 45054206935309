import { faCopyright } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export function Footer() {
    return (
        <div className="text-sm text-remiCharcoal">
            <FontAwesomeIcon className="text-xs mr-6" icon={faCopyright} />
            {new Date().getFullYear()} Cr0wn Gh0ul LLC.
        </div>
    );
}
