import React from "react";
import { ArrowShortUp, Button, Footer } from "components";
import { RemiHero, RemiSplashText } from "./RemiHero/RemiHero";
import { animated, useInView, useSpring } from "@react-spring/web";
import { BaseSplashText, TheBaseModels } from "./BaseModels/BaseModels";
import { RemiRoadmap } from "./RemiRoadmap/RemiRoadmap";
import { Accessories } from "./Accessories/Accessories";

export function Lander() {
    const [remiAnimComplete, setRemiAnimComplete] = React.useState(false);

    let [scrollPosition, setScrollPosition] = React.useState(0);

    const handleScroll = () => {
        const position = window.pageYOffset;
        setScrollPosition(position);
    };

    React.useEffect(() => {
        window.addEventListener("scroll", handleScroll, { passive: true });
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    });

    const animEndCallback = () => {
        setRemiAnimComplete(true);
    };

    // For development to skip anims
    // React.useEffect(() => {
    //     animEndCallback();
    // }, []);

    return (
        <div className="flex w-full flex-col items-center ">
            {/* Full Width Hero */}
            <div className="flex w-full justify-center bg-remiPaperTeal">
                <RemiHero animationDoneCb={animEndCallback} />
            </div>
            {/* Constrain Width For Content If Needed */}
            {!remiAnimComplete && (
                <div className="mt-4">
                    <Button primary fancy onClick={animEndCallback}>
                        Skip
                    </Button>
                </div>
            )}
            <div className="flex flex-col  w-full">
                {remiAnimComplete && <BelowHeroContent />}
                {scrollPosition > 200 && <ScrollUpButton />}
            </div>
        </div>
    );
}

function BelowHeroContent() {
    const useDirectionalSpring = (direction, delay = 350) => {
        return useInView(
            () => ({
                from: {
                    opacity: 0,
                    [direction === "left"
                        ? "left"
                        : direction === "bottom"
                        ? "bottom"
                        : "right"]: "100%",
                },
                to: {
                    opacity: 1,
                    [direction === "left"
                        ? "left"
                        : direction === "bottom"
                        ? "bottom"
                        : "right"]: "0%",
                },
                config: {
                    friction: 10,
                    tension: 25,
                },
            }),
            {
                once: true,
            }
        );
    };

    const [springInLeft1Ref, springInLeft1Spring] = useDirectionalSpring(
        "left",
        0
    );
    const [springInRight1Ref, springInRight1Spring] = useDirectionalSpring(
        "right",
        0
    );
    const [springInLeft2Ref, springInLeft2Spring] = useDirectionalSpring(
        "left",
        0
    );

    const [springInRight2Ref, springInRight2Spring] = useDirectionalSpring(
        "right",
        0
    );

    const [springInLeft3Ref, springInLeft3Spring] = useDirectionalSpring(
        "left",
        0
    );

    const [springUp1Ref, springUp1Spring] = useDirectionalSpring("bottom", 0);

    const getAnimSlideDiv = ({ ref, style, className, children }) => {
        return (
            <animated.div
                ref={ref}
                style={style}
                className={
                    "my-1 relative flex  justify-center w-full p-4 " + className
                }
            >
                <div className="md:max-w-6xl">{children}</div>
            </animated.div>
        );
    };

    return (
        <div className="overflow-hidden">
            {getAnimSlideDiv({
                ref: springInLeft1Ref,
                style: { ...springInLeft1Spring },
                children: <RemiSplashText />,
                className: "shadow-sm",
            })}
            {getAnimSlideDiv({
                ref: springInRight1Ref,
                style: { ...springInRight1Spring },
                children: <TheBaseModels />,
                className: "bg-remiPaperTeal",
            })}
            {getAnimSlideDiv({
                ref: springInLeft2Ref,
                style: { ...springInLeft2Spring },
                children: <BaseSplashText />,
                className: "shadow-sm",
            })}
            {getAnimSlideDiv({
                ref: springInLeft3Ref,
                style: { ...springInLeft3Spring },
                children: <Accessories />,
                className: "bg-remiPaperTeal shadow-sm",
            })}
            {getAnimSlideDiv({
                ref: springInRight2Ref,
                style: { ...springInRight2Spring },
                children: <RemiRoadmap />,
            })}
            {getAnimSlideDiv({
                ref: springUp1Ref,
                style: { ...springUp1Spring },
                children: <Footer />,
            })}
        </div>
    );
}

function ScrollUpButton() {
    const appearSpring = useSpring({
        from: { opacity: 0 },
        to: { opacity: 1 },
    });

    const scrollUp = () => {
        window.scrollTo(0, 0);
    };

    return (
        <animated.div
            style={{ ...appearSpring }}
            onClick={() => scrollUp()}
            className="fixed text-slate-300 hover:text-slate-500 cursor-pointer flex justify-center items-center w-14 h-14 bottom-10 right-10 rounded-full bg-slate-50/50  border border-slate-400/50 hover:border-slate-400 transition-all ease-in-out hover:shadow-sm"
        >
            <ArrowShortUp />
        </animated.div>
    );
}
