const projectName = "R3M1";
const companyName = "Cr0wn Gh0ul LLC";
const siteName = "R3M1.io";
const revisionDate = "March 14, 2023";
const stateOfLaw = "Michigan";

export function ToS() {
    return (
        <div className="flex justify-center">
            <div className="flex flex-col gap-6 font-serif">
                <div>
                    <h1 className="uppercase font-bold">
                        Terms and Conditions
                    </h1>
                    <h3> Last Revised {revisionDate}</h3>
                </div>
                <h2 className="uppercase font-bold text-2xl">
                    Agreemeent Between User and {companyName}
                </h2>
                <p>
                    Welcome to the {projectName} on-chain digital collection.
                    This is the official Terms and Conditions agreement for all{" "}
                    {projectName} services ("Service", "Services") including but
                    not limited to the website {siteName} ("Site"), smart
                    contracts ("Smart Contracts"), and Digital Art Collections
                    issued as Non-Fingible Tokens ("R3M1 NFTs", "Digital
                    Collection", "Digital Art Collection"), all owned and
                    operated by {companyName}.
                </p>
                <p className="font-bold">
                    PLEASE READ ALL OF THE TERMS AND CONDITIONS AGREEMENT
                    <br />
                    <br />
                    Your use of any of the Service constitutes your agreement to
                    all such terms, conditions, and policies outlined below
                </p>
                <div>
                    <h2 className="uppercase font-bold text-xl">Revisions</h2>
                    {companyName} reserves the right, in its sole discretion, to
                    change the Terms under which the Service is offered. The
                    most current version of the Terms will supersede all
                    previous versions. {companyName} encourages you to
                    periodically review the Terms to stay informed of our
                    updates.
                </div>
                <div>
                    <h2 className="uppercase font-bold text-xl">
                        No Unlawful or Prohibited Use
                    </h2>
                    <p>
                        You are granted a non-exclusive, non-transferable,
                        revocable license to access and use the Service strictly
                        in accordance with these terms of use. As a condition of
                        your use of the Service, you warrant to {companyName}{" "}
                        that you will not use the Service for any purpose that
                        is unlawful or prohibited by these Terms. You may not
                        use the Service in any manner which could damage,
                        disable, overburden, or impair the Service or interfere
                        with any other party's use and enjoyment of the Service.
                        You may not obtain or attempt to obtain any materials or
                        information through any means not intentionally made
                        available or provided for through the Service. All
                        content included as part of the Service, such as text,
                        graphics, logos, images, as well as the compilation
                        thereof, and any software used on the Service, is the
                        property of
                        {companyName} or its suppliers and protected by
                        copyright and other laws that protect intellectual
                        property and proprietary rights. You agree to observe
                        and abide by all copyright and other proprietary
                        notices, legends or other restrictions contained in any
                        such content and will not make any changes thereto. You
                        will not modify, publish, transmit, reverse engineer,
                        participate in the transfer or sale, create derivative
                        works, or in any way exploit any of the content, in
                        whole or in part, found on the Service. {companyName}{" "}
                        content is not for resale. Your use of the Service does
                        not entitle you to make any unauthorized use of any
                        protected content, and in particular you will not delete
                        or alter any proprietary rights or attribution notices
                        in any content. You will use protected content solely
                        for your personal use, and will make no other use of the
                        content without the express written permission of{" "}
                        {companyName} and the copyright owner. You agree that
                        you do not acquire any ownership rights in any protected
                        content. We do not grant you any licenses, express or
                        implied, to the intellectual property of {companyName}{" "}
                        or our licensors except as expressly authorized by these
                        Terms.
                    </p>
                    <br />
                    <p>
                        In addition you must not in any way attempt to
                        dismantle, reverse engineer, or interrupt the Service
                    </p>
                    <br />
                    <p>You may also not use the Service:</p>
                    <br />
                    <p>
                        a. To impersonate or otherwise attempt to appear as an
                        operator or owner of {companyName} or misguide others
                        that you are an affiliate of either {companyName} or{" "}
                        {projectName}
                    </p>
                    <br />
                    <p>
                        b. In any way that violates applicable international,
                        federal, state, or local law or regulation.
                    </p>
                    <br />
                    <p>
                        c. Use any software that interupts or interferes with
                        the proper use of the Service.
                    </p>
                    <p>
                        Attempt any types of denial of service, including
                        distributed attempts, against the Service.
                    </p>
                </div>
                <div>
                    <h2 className="uppercase font-bold text-xl">
                        Children Under 18
                    </h2>
                    <p>
                        {companyName} does not knowingly collect, either online
                        or offline, personal information from persons under the
                        age of eighteen. If you are under 18, you are <b>NOT</b>{" "}
                        permitted to use the Service.
                    </p>
                </div>
                <div>
                    <h2 className="uppercase font-bold text-xl">
                        Disclaimers and Assumption of Risk
                    </h2>
                    <p>
                        The Service is provided as is without warranty of any
                        kind. No expectations of value are extended or
                        guaranteed by {companyName} regarding any of the Service
                        including any NFTs oor the Digital Art Collection. You
                        acknowledge that the secondary market of NFT sales,
                        including those but not limited to NFTs obtained through
                        the Service, are volatile, speculative, and that the
                        Service provided should therefore not be considered an
                        investment. You agree to assume all risk associated with
                        interacting with the Service, and the risk of change in
                        value of collected NFTs from the Service. You agree that
                        any information provided by the Service is NOT an
                        invitation to enter into an agreement for any investment
                        purpose, nor does the Service represent any offer to
                        purchase securities, shares, or any any other form of
                        financial products. {projectName} is strictly a
                        collection of art and has not been registered or
                        approved by any financial regulators. You agree and
                        acknowledge that your interaction with the Service is in
                        compliance with all relative laws and that the future of
                        NFT laws is unknown which could affect your ability to
                        interact with the Service, or the value of NFTs obtained
                        from the Service and assume all risks associated.
                    </p>
                </div>
                <div>
                    <h2 className="uppercase font-bold text-xl">
                        Indemnification
                    </h2>
                    <p>
                        You agree to indemnify, defend and hold harmless{" "}
                        {companyName}, its officers, directors, employees,
                        agents and third parties, for any losses, costs,
                        liabilities and expenses (including reasonable
                        attorney's fees) relating to or arising out of your user
                        of or inability to use the Service, your violation of
                        any terms of this Agreement of your violation of any
                        rights of a third party, or your violation of any
                        applicable laws, rules, or regulations. {companyName}{" "}
                        reserves the right, at its own cost, to assume the
                        exclusive defense and control of any matter otherwise
                        subject to indemnification by you, in which even you
                        will fully cooperate with {siteName} in asserting any
                        available defenses.
                    </p>
                </div>
                <div>
                    <h2 className="uppercase font-bold text-xl">
                        International Users
                    </h2>
                    <p>
                        The Service is controlled, operated and administered by
                        {companyName} from our offices within the USA. If you
                        access the Service from a location outside the USA, you
                        are responsible for compliance with all local laws. You
                        agree that you will not use the {companyName} Content
                        accessed through the Service in any country or in any
                        manner prohibited by any applicable laws, restrictions
                        or regulations.
                    </p>
                </div>
                <div>
                    <h2 className="uppercase font-bold text-xl">Arbitration</h2>
                    <p>
                        In the event the parties are not able to resolve any
                        dispute between them arising out of or concerning these
                        Terms and Conditions, or any provisions hereof, whether
                        in contract, tort, or otherwise at law or in equity for
                        damages or any other relief, then such dispute shall be
                        resolved only by final and binding arbitration pursuant
                        to the Federal Arbitration Act, conducted by a single
                        neutral arbitrator and administered by the American
                        Arbitration Association, or a similar arbitration
                        service selected by the parties, in a location mutually
                        agreed upon by the parties. The arbitrator's award shall
                        be final, and judgment may be entered upon it in any
                        court having jurisdiction. In the event that any legal
                        or equitable action, proceeding or arbitration arises
                        out of or concerns these Terms and Conditions, the
                        prevailing party shall be entitled to recover its costs
                        and reasonable attorney's fees. The parties agree to
                        arbitrate all disputes and claims in regards to these
                        Terms and Conditions or any disputes arising as a result
                        of these Terms and Conditions, whether directly or
                        indirectly, including Tort claims that are a result of
                        these Terms and Conditions. The parties agree that the
                        Federal Arbitration Act governs the interpretation and
                        enforcement of this provision. The entire dispute,
                        including the scope and enforceability of this
                        arbitration provision shall be determined by the
                        Arbitrator. This arbitration provision shall survive the
                        termination of these Terms and Conditions.
                    </p>
                </div>
                <div>
                    <h2 className="uppercase font-bold text-xl">
                        Class Action Waiver
                    </h2>
                    <p>
                        Class Action Waiver Any arbitration under these Terms
                        and Conditions will take place on an individual basis;
                        class arbitrations and class/representative/collective
                        actions are not permitted. THE PARTIES AGREE THAT A
                        PARTY MAY BRING CLAIMS AGAINST THE OTHER ONLY IN EACH'S
                        INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS
                        MEMBER IN ANY PUTATIVE CLASS, COLLECTIVE AND/ OR
                        REPRESENTATIVE PROCEEDING, SUCH AS IN THE FORM OF A
                        PRIVATE ATTORNEY GENERAL ACTION AGAINST THE OTHER.
                        Further, unless both you and companyName agree
                        otherwise, the arbitrator may not consolidate more than
                        one person's claims, and may not otherwise preside over
                        any form of a representative or class proceeding.
                    </p>
                </div>
                <div>
                    <h2 className="uppercase font-bold text-xl">
                        Liability Disclaimer
                    </h2>
                    <p>
                        THE INFORMATION, SOFTWARE, PRODUCTS, AND SERVICES
                        INCLUDED IN OR AVAILABLE THROUGH THE SITE MAY INCLUDE
                        INACCURACIES OR TYPOGRAPHICAL ERRORS. CHANGES ARE
                        PERIODICALLY ADDED TO THE INFORMATION HEREIN.
                        {companyName.toUpperCase()} AND/OR ITS SUPPLIERS MAY
                        MAKE IMPROVEMENTS AND/OR CHANGES IN THE SITE AT ANY
                        TIME. {companyName.toUpperCase()}
                        AND/OR ITS SUPPLIERS MAKE NO REPRESENTATIONS ABOUT THE
                        SUITABILITY, RELIABILITY, AVAILABILITY, TIMELINESS, AND
                        ACCURACY OF THE INFORMATION, SOFTWARE, PRODUCTS,
                        SERVICES AND RELATED GRAPHICS CONTAINED ON THE SITE FOR
                        ANY PURPOSE. TO THE MAXIMUM EXTENT PERMITTED BY
                        APPLICABLE LAW, ALL SUCH INFORMATION, SOFTWARE,
                        PRODUCTS, SERVICES AND RELATED GRAPHICS ARE PROVIDED "AS
                        IS" WITHOUT WARRANTY OR CONDITION OF ANY KIND.
                        {companyName.toUpperCase()} AND/OR ITS SUPPLIERS HEREBY
                        DISCLAIM ALL WARRANTIES AND CONDITIONS WITH REGARD TO
                        THIS INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND
                        RELATED GRAPHICS, INCLUDING ALL IMPLIED WARRANTIES OR
                        CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
                        PURPOSE, TITLE AND NON-INFRINGEMENT. TO THE MAXIMUM
                        EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL
                        {companyName.toUpperCase()} AND/OR ITS SUPPLIERS BE
                        LIABLE FOR ANY DIRECT, INDIRECT, PUNITIVE, INCIDENTAL,
                        SPECIAL, CONSEQUENTIAL DAMAGES OR ANY DAMAGES WHATSOEVER
                        INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF USE,
                        DATA OR PROFITS, ARISING OUT OF OR IN ANY WAY CONNECTED
                        WITH THE USE OR PERFORMANCE OF THE SITE, WITH THE DELAY
                        OR INABILITY TO USE THE SITE OR RELATED SERVICES, THE
                        PROVISION OF OR FAILURE TO PROVIDE SERVICES, OR FOR ANY
                        INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED
                        GRAPHICS OBTAINED THROUGH THE SITE, OR OTHERWISE ARISING
                        OUT OF THE USE OF THE SITE, WHETHER BASED ON CONTRACT,
                        TORT, NEGLIGENCE, STRICT LIABILITY OR OTHERWISE, EVEN IF
                        {companyName.toUpperCase()} OR ANY OF ITS SUPPLIERS HAS
                        BEEN ADVISED OF THE POSSIBILITY OF DAMAGES. BECAUSE SOME
                        STATES/JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR
                        LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL
                        DAMAGES, THE ABOVE LIMITATION MAY NOT APPLY TO YOU. IF
                        YOU ARE DISSATISFIED WITH ANY PORTION OF THE SITE, OR
                        WITH ANY OF THESE TERMS OF USE, YOUR SOLE AND EXCLUSIVE
                        REMEDY IS TO DISCONTINUE USING THE SITE.
                    </p>
                </div>
                <div>
                    <h2 className="uppercase font-bold text-2xl">
                        Termination/Access Restriction
                    </h2>
                    <p>
                        {companyName} reserves the right, in its sole
                        discretion, to terminate your access to the Site and the
                        related services or any portion thereof at any time,
                        without notice. To the maximum extent permitted by law,
                        this agreement is governed by the laws of the State of
                        {stateOfLaw} and you hereby consent to the exclusive
                        jurisdiction and venue of courts in {stateOfLaw} in all
                        disputes arising out of or relating to the use of the
                        Site. Use of the Site is unauthorized in any
                        jurisdiction that does not give effect to all provisions
                        of these Terms, including, without limitation, this
                        section. You agree that no joint venture, partnership,
                        employment, or agency relationship exists between you
                        and {companyName} as a result of this agreement or use
                        of the Site. {companyName}'s performance of this
                        agreement is subject to existing laws and legal process,
                        and nothing contained in this agreement is in derogation
                        of
                        {companyName}'s right to comply with governmental, court
                        and law enforcement requests or requirements relating to
                        your use of the Site or information provided to or
                        gathered by {companyName} with respect to such use. If
                        any part of this agreement is determined to be invalid
                        or unenforceable pursuant to applicable law including,
                        but not limited to, the warranty disclaimers and
                        liability limitations set forth above, then the invalid
                        or unenforceable provision will be deemed superseded by
                        a valid, enforceable provision that most closely matches
                        the intent of the original provision and the remainder
                        of the agreement shall continue in effect. Unless
                        otherwise specified herein, this agreement constitutes
                        the entire agreement between the user and {companyName}
                        with respect to the Site and it supersedes all prior or
                        contemporaneous communications and proposals, whether
                        electronic, oral or written, between the user and
                        {companyName} with respect to the Site. A printed
                        version of this agreement and of any notice given in
                        electronic form shall be admissible in judicial or
                        administrative proceedings based upon or relating to
                        this agreement to the same extent and subject to the
                        same conditions as other business documents and records
                        originally generated and maintained in printed form. It
                        is the express wish to the parties that this agreement
                        and all related documents be written in English.
                    </p>
                </div>
            </div>
        </div>
    );
}
