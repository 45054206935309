import { content } from "content/content";
import { animated, useInView } from "@react-spring/web";

const useMultiBottomUpSprings = (springCount) => {
    let springs = [];
    for (let i = 0; i < springCount; i++) {
        springs.push(
            // eslint-disable-next-line react-hooks/rules-of-hooks
            useInView(
                () => ({
                    from: {
                        opacity: 0,
                    },
                    to: {
                        opacity: 1,
                    },
                    config: {
                        friction: 10,
                        tension: 25,
                    },
                }),
                {
                    once: true,
                }
            )
        );
    }
    return springs;
};

export function Faq() {
    const divRefSprings = useMultiBottomUpSprings(
        content.FAQContent.questions.length
    );

    const getQuestionsAndAnswers = () => {
        const returnedDivs = [];
        for (let i = 0; i < content.FAQContent.questions.length; i++) {
            let question = content.FAQContent.questions[i];
            let answer = content.FAQContent.answers[i];
            returnedDivs.push(
                <animated.div
                    ref={divRefSprings[i][0]}
                    style={divRefSprings[i][1]}
                    className="flex flex-col border border-slate-400/25"
                >
                    <div className="flex p-2 text-slate-800">
                        <h3 className="text-xl font-bold">{question}</h3>
                    </div>
                    <div className="flex p-2 text-slate-700">
                        <p>{answer}</p>
                    </div>
                </animated.div>
            );
        }
        return returnedDivs;
    };

    return (
        <div className="flex justify-center w-full ">
            <div className="flex flex-col gap-8 p-8 max-w-6xl">
                {getQuestionsAndAnswers()}
            </div>
        </div>
    );
}
