import React from "react";
import { RemiBuilder } from "components/remiBuilder/RemiBuilder";
import { content } from "content/content";
import TypeWriter from "react-typewriter";


export function RemiHero({ animationDoneCb }) {
    const [secondLineStart, setSecondLineStart] = React.useState(0);

    const animationCompleteCb = () => {
        animationDoneCb();
    };

    return (
        <div className="w-full ">
            <div className="flex flex-col gap-4 items-center py-6 min-h-[300px] min-w-full w-full">
                <div className="flex flex-grow-0 min-h-[32px]">
                    <TypeWriter
                        typing={1}
                        minDelay={40}
                        maxDelay={110}
                        onTypingEnd={() =>
                            setTimeout(() => setSecondLineStart(1), 1000)
                        }
                    >
                        <div className="text-remiGreen text-2xl">
                            s4y h3110 to{" "}
                            <span className="text-remiRed">R3M1</span>
                        </div>
                    </TypeWriter>
                </div>
                <div className="flex flex-grow h-full w-full justify-center">
                    <RemiBuilder />
                </div>
                <div className="flex-grow-0 min-h-[24px]">
                    <TypeWriter
                        typing={secondLineStart}
                        minDelay={45}
                        maxDelay={85}
                        onTypingEnd={animationCompleteCb}
                    >
                        <div className="text-remiCharcoal">
                            a new onchain digital collection
                        </div>
                    </TypeWriter>
                </div>
            </div>
        </div>
    );
}

export function RemiSplashText() {
    return (
        <div>
            <p className="text-lg">{content.HeroSplashText}</p>
        </div>
    );
}
