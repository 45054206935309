export const content = {
    HeroSplashText: (
        <>
            <span className="text-remiRed">R3M1</span> is an on-chain digital
            art collection consisting of 6000 robots. These robots, are designed
            to be a modern, cute, and highly accesible art collection due to
            being stored right on chain. They will be available in multiple
            colors and adorn various cute accessories to showcase their unique
            personalities.
        </>
    ),
    BaseModelText: (
        <>
            <span className="text-remiOrange">The Base Models</span> are the the
            foundation of <span className="text-remiRed">R3M1</span> and, as
            their name suggests, will serve as the base of the entire
            collection. The bases will are manufactured in a range of multiple
            colors, with some bases even featuring rare and unique color
            options.
        </>
    ),
    AccessoryText: (
        <>
            During base manufacturing,{" "}
            <span className="text-remiRed">R3M1</span>s are supplied an{" "}
            <span className="text-remiOrange">Accessory</span>. These{" "}
            <span className="text-remiOrange">Accessories</span> are garnishes
            on the base R3MI model that personalize them.{" "}
            <span className="text-remiRed">R3M1</span> will be manufactured with
            one or multiple unique artistic pieces including expressions,
            handhelds, hats, glasses, backgrounds to make each{" "}
            <span className="text-remiRed">R3M1</span> unique.
        </>
    ),
    RoadmapText: (
        <>
            While the roadmap for <span className="text-remiRed">R3M1</span> was
            initially slightly more complex with a thought of introducing{" "}
            <span className="text-remiOrange">Accessories</span> later in the{" "}
            <span className="text-remiRed">R3M1</span> life cycle,
            considerations from the team have decided instead to simplify the
            road map. <br />
            <br />
            The roadmap for <span className="text-remiRed">R3M1</span> has
            become very starightforward. It is a modern and elegant art
            collection that dips the toes of the artist into the digital
            collectible space. <br /> <br /> The future of{" "}
            <span className="text-remiRed">R3M1</span> is static and known, in that,
            there are currently not any plans to expand{" "}
            <span className="text-remiRed">R3M1</span> beyond a simple art
            collection, but we hope you enjoy the art from the artist and being
            a part of the on-chain art community knowing that each{" "}
            <span className="text-remiRed">R3M1</span> is stored directly
            on-chain.
        </>
    ),
    FAQContent: {
        questions: [
            "1. What is R3M1?",
            "2: How do you mint a R3M1?",
            "3: How do you pronounce R3M1?",
            "4: Are any R3M1s more rare than others?",
            "5: Are you partenering with other projects?",
            "6: Are you looking for additional developers, artist, etc?",
            "7: Who is the artist?",
        ],
        answers: [
            "R3M1 is a digital NFT art collection that is entirely on-chain and features various base model droids that have unique mixes of accessories.",
            "R3MI will be available for mint on the this R3MI website.",
            "It is pronounced 'Remi' (REH-mee).",
            "There is rarity in the base model types and colors, as well as the traits which can be applied to them.",
            "At this time we are not partnering with other projects.",
            "We are not looking for additional personnel of any kind at this point.",
            "The arist working with us has elected to remain anonymous due to fear of backlash in their community. This is a toe-dip into the space and they wish to remain as such for the time being. If this changes a link to their art and portfolio will be posted here in the future.    ",
        ],
    },
    About: (
        <>
            <h2 className="text-2xl text-remiGreen">
                So what is "<span className="text-remiRed">R3M1</span>"?
            </h2>{" "}
            <br />
            <span className="text-remiRed">R3M1</span> is an upcoming digital
            collection by on-chain enthusiasts{" "}
            <span className="text-remiGreen">@Cr0wn_Gh0ul</span>,{" "}
            <span className="text-remiGreen">@LeoV</span>, and{" "}
            <span className="text-remiGreen">@CatThatPrograms</span>
            <br />
            <br />
            The mission of <span className="text-remiRed">R3M1</span> is simple
            and aims to help expand the already dynamic community of on-chain
            collectors by providing an additional art collection from an
            illustrator looking to dip their toes into digital collections.
            <br /> <br />
            The <span className="text-remiRed">R3M1</span> collection is
            comprised of the <span className="text-remiGreen">Base Models</span>
            , which will serve as the building blocks of the entire collection
            and be adorned with{" "}
            <span className="text-remiGreen">Accessories</span> to provide each
            robot a unique personality.
            <br /> <br />
            We hope that <span className="text-remiRed">R3M1</span> becomes a
            unique artistic experience and inspires creators and artists to
            engage with on-chain art.
        </>
    ),
};
