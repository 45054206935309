const creator = "Cr0wn Gh0ul LLC";
const siteName = "r3m1.io";

export function NftLicense() {
    return (
        <div className="flex items-start w-full">
            <div className="flex justify-start flex-col gap-6 font-serif ">
                <div>
                    <h1 className="uppercase underline text-blue-400 font-bold text-2xl">
                        <a
                            href="https://www.nftlicense.org/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            NFT License
                        </a>
                    </h1>
                </div>
                <div>
                    <h2 className="uppercase font-bold text-2xl">
                        Version 2.0
                    </h2>
                    <p>Last Revised: November 5, 2018</p>
                </div>
                <div className="flex gap-4 flex-col">
                    <h2 className="uppercase font-bold text-2xl">
                        0. Background; Instructions for Use.
                    </h2>
                    <p>
                        The NFT License (this “License”) is built to support
                        applications for blockchain technologies, and to help
                        clarify a user’s rights in artistic overlays as the
                        owner of a non-fungible token (“NFTs”).
                    </p>
                    <p>
                        Dapper Labs Inc. (“DLI”) created this License as an
                        open-source resource for the wider community to
                        encourage adoption of the ERC-721 standard. DLI’s goal
                        is to positively shape the future of NFTs for creators
                        of blockchain-based art (each, for the purposes of this
                        License, a “Creator”) by demystifying the associated
                        user rights while empowering Creators to license their
                        work at scale.
                    </p>
                    <p>
                        DLI intends to use this License in each of its current
                        and upcoming apps (including CryptoKitties®) to help
                        ensure that NFT owners enjoy broad and meaningful rights
                        in the art associated with their purchased NFTs. DLI
                        encourages other Creators to adopt this License as well.
                    </p>
                    <p>
                        When a Creator leverages this License, the Creator
                        should replace the word “Creator” with the name of its
                        company or organization and add a contact email in
                        section 5. Other than the foregoing modification, DLI
                        does not permit other changes to this License. If a
                        Creator wishes to make other changes to this License, it
                        may not refer to the resulting license as the NFT
                        License, or any variant thereof. Nothing in this License
                        gives a Creator or any third party any rights in or to
                        the names, marks, or logos of Dapper Labs Inc.
                        (including, without limitation, the right to use the
                        “NFT License” mark), or in or to any intellectual
                        property rights of DLI, its subsidiaries or affiliates,
                        all of which are expressly reserved.
                    </p>{" "}
                </div>
                <div className="flex gap-4 flex-col">
                    <h2 className="uppercase font-bold text-2xl">
                        1. Definitions.
                    </h2>
                    <p>
                        “Art” means any art, design, and drawings that may be
                        associated with an NFT that you Own.
                    </p>
                    <p>
                        "NFT" means any blockchain-tracked, non-fungible token,
                        such as those conforming to the ERC-721 standard.
                    </p>
                    <p>
                        “Own” means, with respect to an NFT, an NFT that you
                        have purchased or otherwise rightfully acquired from a
                        legitimate source, where proof of such purchase is
                        recorded on the relevant blockchain.
                    </p>
                    <p>
                        “Extensions” means third party designs that: (i) are
                        intended for use as extensions or overlays to the Art,
                        (ii) do not modify the underlying Art, and (iii) can be
                        removed at any time without affecting the underlying
                        Art.
                    </p>
                    <p>“Purchased NFT” means an NFT that you Own.</p>
                    <p>
                        “Third Party IP” means any third party patent rights
                        (including, without limitation, patent applications and
                        disclosures), copyrights, trade secrets, trademarks,
                        know-how or any other intellectual property rights
                        recognized in any country or jurisdiction in the world.
                    </p>
                </div>{" "}
                <div className="flex gap-4 flex-col">
                    <h2 className="uppercase font-bold text-2xl">
                        2. Ownership.
                    </h2>
                    <p>
                        You acknowledge and agree that {creator} (or, as
                        applicable, its licensors) owns all legal right, title
                        and interest in and to the Art, and all intellectual
                        property rights therein. The rights that you have in and
                        to the Art are limited to those described in this
                        License. {creator} reserves all rights in and to the Art
                        not expressly granted to you in this License.
                    </p>
                </div>{" "}
                <div className="flex gap-4 flex-col">
                    <h2 className="uppercase font-bold text-2xl">
                        3. License.
                    </h2>
                    <p>
                        a. General Use. Subject to your continued compliance
                        with the terms of this License, {creator} grants you a
                        worldwide, non-exclusive, non-transferable, royalty-free
                        license to use, copy, and display the Art for your
                        Purchased NFTs, along with any Extensions that you
                        choose to create or use, solely for the following
                        purposes: (i) for your own personal, non-commercial use;
                        (ii) as part of a marketplace that permits the purchase
                        and sale of your NFTs, provided that the marketplace
                        cryptographically verifies each NFT owner’s rights to
                        display the Art for their Purchased NFTs to ensure that
                        only the actual owner can display the Art; or (iii) as
                        part of a third party website or application that
                        permits the inclusion, involvement, or participation of
                        your NFTs, provided that the website/application
                        cryptographically verifies each NFT owner’s rights to
                        display the Art for their Purchased NFTs to ensure that
                        only the actual owner can display the Art, and provided
                        that the Art is no longer visible once the owner of the
                        Purchased NFT leaves the website/application.
                    </p>
                    <p>
                        b. Commercial Use. Subject to your continued compliance
                        with the terms of this License, {creator} grants you a
                        limited, worldwide, non-exclusive, non-transferable
                        license to use, copy, and display the Art for your
                        Purchased NFTs for the purpose of commercializing your
                        own merchandise that includes, contains, or consists of
                        the Art for your Purchased NFTs (“Commercial Use”),
                        provided that such Commercial Use does not result in you
                        earning more than One Hundred Thousand Dollars
                        ($100,000) in gross revenue each year. For the sake of
                        clarity, nothing in this Section 3.b will be deemed to
                        restrict you from (i) owning or operating a marketplace
                        that permits the use and sale of NFTs generally,
                        provided that the marketplace cryptographically verifies
                        each NFT owner’s rights to display the Art for their
                        Purchased NFTs to ensure that only the actual owner can
                        display the Art; (ii) owning or operating a third party
                        website or application that permits the inclusion,
                        involvement, or participation of NFTs generally,
                        provided that the third party website or application
                        cryptographically verifies each NFT owner’s rights to
                        display the Art for their Purchased NFTs to ensure that
                        only the actual owner can display the Art, and provided
                        that the Art is no longer visible once the owner of the
                    </p>

                    <p>
                        Purchased NFT leaves the website/application; or (iii)
                        earning revenue from any of the foregoing, even where
                        such revenue is in excess of $100,000 per year.
                    </p>
                </div>{" "}
                <div className="flex gap-4 flex-col">
                    <h2 className="uppercase font-bold text-2xl">
                        4. Restrictions.
                    </h2>
                    <p>
                        You agree that you may not, nor permit any third party
                        to do or attempt to do any of the foregoing without
                        {creator}’s express prior written consent in each case:
                        (i) modify the Art for your Purchased NFT in any way,
                        including, without limitation, the shapes, designs,
                        drawings, attributes, or color schemes (your use of
                        Extensions will not constitute a prohibited modification
                        hereunder); (ii) use the Art for your Purchased NFTs to
                        advertise, market, or sell any third party product or
                        service; (iii) use the Art for your Purchased NFTs in
                        connection with images, videos, or other forms of media
                        that depict hatred, intolerance, violence, cruelty, or
                        anything else that could reasonably be found to
                        constitute hate speech or otherwise infringe upon the
                        rights of others; (iv) use the Art for your Purchased
                        NFTs in movies, videos, or any other forms of media,
                        except to the limited extent that such use is expressly
                        permitted in Section 3(b) above or solely for your own
                        personal, non-commercial use; (v) sell, distribute for
                        commercial gain (including, without limitation, giving
                        away in the hopes of eventual commercial gain), or
                        otherwise commercialize merchandise that includes,
                        contains, or consists of the Art for your Purchased
                        NFTs, except as expressly permitted in Section 3(b)
                        above; (vi) attempt to trademark, copyright, or
                        otherwise acquire additional intellectual property
                        rights in or to the Art for your Purchased NFTs; or
                        (vii) otherwise utilize the Art for your Purchased NFTs
                        for your or any third party’s commercial benefit. To the
                        extent that Art associated with your Purchased NFTs
                        contains Third Party IP (e.g., licensed intellectual
                        property from a celebrity, athlete, or other public
                        figure), you understand and agree as follows: (w) that
                        you will not have the right to use such Third Party IP
                        in any way except as incorporated in the Art, and
                        subject to the license and restrictions contained
                        herein; (x) that the Commercial Use license in Section
                        3(b) above will not apply; (y) that, depending on the
                        nature of the license granted from the owner of the
                        Third Party IP, {creator} may need to pass through
                        additional restrictions on your ability to use the Art;
                        and (z) to the extent that {creator} informs you of such
                        additional restrictions in writing (email is
                        permissible), you will be responsible for complying with
                        all such restrictions from the date that you receive the
                        notice, and that failure to do so will be deemed a
                        breach of this license. The restriction in Section 4
                        will survive the expiration or termination of this
                        License.
                    </p>
                </div>{" "}
                <div className="flex gap-4 flex-col">
                    <h2 className="uppercase font-bold text-2xl">
                        5. Terms of License.
                    </h2>
                    <p>
                        The license granted in Section 3 above applies only to
                        the extent that you continue to Own the applicable
                        Purchased NFT. If at any time you sell, trade, donate,
                        give away, transfer, or otherwise dispose of your
                        Purchased NFT for any reason, the license granted in
                        Section 3 will immediately expire with respect to those
                        NFTs without the requirement of notice, and you will
                        have no further rights in or to the Art for those NFTs.
                        If you exceed the $100,000 limitation on annual gross
                        revenue set forth in Section 3.b above, you will be in
                        breach of this License, and must send an email to
                        {creator} at admin@{siteName} within fifteen (15) days,
                        with the phrase “NFT License - Commercial Use” in the
                        subject line, requesting a discussion with {creator}{" "}
                        regarding entering into a broader license agreement or
                        obtaining an exemption (which may be granted or withheld
                        in
                        {creator}’s sole and absolute discretion). If you exceed
                        the scope of the license grant in Section 3.b without
                        entering into a broader license agreement with or
                        obtaining an exemption from {creator}, you acknowledge
                        and agree that: (i) you are in breach of this License;
                        (ii) in addition to any remedies that may be available
                        to
                        {creator} at law or in equity, the {creator} may
                        immediately terminate this License, without the
                        requirement of notice; and (iii) you will be responsible
                        to reimburse
                        {creator} for any costs and expenses incurred by{" "}
                        {creator}
                        during the course of enforcing the terms of this License
                        against you.
                    </p>
                </div>
            </div>
        </div>
    );
}
