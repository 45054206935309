import RemiMascotSmallImg from "assets/remi_mascot.png";
import { ArrowUturnUp, BarMenuIcon, RemiArm } from "components/icons";
import { usePrevious } from "hooks/usePrevious";
import { useWindowSize } from "hooks/useWindowSize";
import { useState, useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
import { faTwitter, faDiscord } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Web3Status } from "components/web3Status/Web3Status";

export function Header() {
    const windowSize = useWindowSize();
    const [mHeaderHeight, setMHeaderHeight] = useState(0);
    const prevMHeader = usePrevious(mHeaderHeight);

    useEffect(() => {
        const mobileHeaderHeight =
            document.getElementById("mobile-header").clientHeight;
        if (prevMHeader !== mobileHeaderHeight) {
            setMHeaderHeight(mobileHeaderHeight);
        }
    }, [windowSize.height, windowSize.width, mHeaderHeight, prevMHeader]);

    return (
        <div className="relative z-30">
            <div
                className="md:hidden flex"
                style={{ paddingTop: `${mHeaderHeight}px` }}
            >
                <MobileHeader />
            </div>
            <div className="hidden md:flex">
                <DesktopHeader />
            </div>
        </div>
    );
}

function DesktopHeader() {
    const location = useLocation();
    const pathname = location.pathname;

    const genMenuItemObj = (name, path) => ({ name: name, path: path });
    const desktopMenuItems = [
        genMenuItemObj("Home", "/"),
        genMenuItemObj("About", "/about"),
        genMenuItemObj("FAQ", "/faq"),
        genMenuItemObj("Terms", "/tos"),
    ];

    if (process.env.REACT_APP_MINT_AVAILABLE) {
        desktopMenuItems.splice(1, 0, genMenuItemObj("Mint", "/mint"));
    }

    const Menu = () =>
        desktopMenuItems.map((menuItem) => (
            <Link
                key={menuItem.path}
                to={menuItem.path}
                className={`${
                    menuItem.path === pathname
                        ? "underline"
                        : "hover:underline hover:cursor-pointer"
                }`}
            >
                {menuItem.name}
            </Link>
        ));

    return (
        <div className="flex justify-center items-center w-full bg-white min-h-[64px] shadow-sm px-8 mb-1">
            <div className="flex md:max-w-6xl w-full">
                <div className="flex items-center gap-8 flex-grow">
                    <Menu />
                </div>
                <div>{location.pathname === "/mint" && <Web3Status />}</div>
            </div>
        </div>
    );
}

function MobileHeader() {
    const [menuOpen, setMenuOpen] = useState(false);
    const location = useLocation();
    const pathname = location.pathname;

    const RemiMascotSmall = () => (
        <img
            src={RemiMascotSmallImg}
            style={{ width: "12.5%", maxWidth: "320px" }}
            alt="R3M1 MASCOT"
        />
    );

    const MobileMenuButton = () => (
        <div
            className="flex h-11 w-11 p-2 rounded-md hover:bg-slate-100 hover:cursor-pointer"
            onClick={() => setMenuOpen((s) => !s)}
        >
            {menuOpen ? (
                <ArrowUturnUp strokeColor={"rgba(0,0,0,.65)"} />
            ) : (
                <BarMenuIcon strokeColor={"rgba(0,0,0,.65)"} />
            )}
        </div>
    );

    const Socials = () => {
        const SocialButton = ({ adText, hrefDest, faIcon, iconColor }) => (
            <a href={hrefDest} target="_blank" rel="noopener noreferrer">
                <div className="min-w-[140px] p-4 flex justify-center items-center rounded-lg border border-remiPaperTeal hover:border-none">
                    <div className="text-slate-600 text-sm">{adText}</div>
                    <FontAwesomeIcon
                        icon={faIcon}
                        style={{ color: `${iconColor}` }}
                        className="text-2xl ml-4"
                    />
                </div>
            </a>
        );

        return (
            <div className="flex justify-center items-center gap-6 h-full flex-col space-around">
                <SocialButton
                    hrefDest="https://twitter.com/R3M1_NFT"
                    faIcon={faTwitter}
                    iconColor="#1da1f2"
                    adText={"@R3M1_NFT"}
                />
                <SocialButton
                    hrefDest="https://t.co/NvS2Hmgu5h"
                    faIcon={faDiscord}
                    iconColor="#5865F2"
                    adText="JOIN R3M1"
                />
            </div>
        );
    };

    const MenuContents = () => {
        const MenuRow = ({ title, destination }) => {
            const isActive = destination === pathname;
            return (
                <Link
                    to={destination}
                    onClick={() => {
                        setMenuOpen(false);
                        window.scrollTo(0);
                    }}
                >
                    <div className="flex hover:cursor-pointer hover:bg-slate-50">
                        <div className="p-3 text-lg text-remiCharcoal">
                            {title}
                        </div>
                        {isActive && (
                            <div className="flex items-center w-[32px]">
                                <RemiArm />
                            </div>
                        )}
                    </div>
                </Link>
            );
        };

        return (
            <div className="bg-white">
                <MenuRow title={"Home"} destination="/" />
                <MenuRow title={"Mint"} destination="/mint" />
                <MenuRow title={"About"} destination="/about" />
                <MenuRow title={"Faq"} destination="/faq" />
                <MenuRow title={"Terms"} destination="/tos" />
            </div>
        );
    };

    const flexCenter = "flex flex-grow justify-center items-center";

    const linkCount = 4; // Amount of links in mobile menu
    const hClosedOffset = 52 * linkCount;

    return (
        <>
            <div className="fixed w-full z-30 top-0 flex flex-grow">
                <div
                    style={{
                        top: menuOpen ? 0 : -hClosedOffset,
                    }}
                    className={`absolute w-full flex flex-col bg-white shadow-sm flex-grow transition-all ease-in-out duration-700`}
                >
                    <div className="flex flex-grow w-full border-b">
                        <div className="flex-grow ">
                            <MenuContents />
                        </div>
                        <div className="flex-shrink pr-6 bg-white">
                            <Socials />
                        </div>
                    </div>
                    <div id="mobile-header" className="flex flex-grow p-4 px-8">
                        <div className={`${flexCenter}`}>
                            <h1 className="text-remiRed text-2xl">R3M1</h1>
                        </div>
                        <div className={`${flexCenter}`}>
                            <RemiMascotSmall />
                        </div>
                        <div className={`${flexCenter}`}>
                            <MobileMenuButton />
                        </div>
                    </div>
                </div>
            </div>
            {/* Menu Closer */}
            <div
                onClick={() => setMenuOpen(false)}
                className={`absolute bottom-0 h-[100vh] w-full bg-transparent ${
                    menuOpen ? "" : " hidden"
                }`}
            />
        </>
    );
}
