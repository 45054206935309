export function RemiHead() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      version="1.1"
      viewBox="0 0 346.274 472.252"
      xmlSpace="preserve"
    >
      <defs>
        <clipPath clipPathUnits="userSpaceOnUse">
          <path
            fill="#465d59"
            fillOpacity="1"
            fillRule="evenodd"
            stroke="none"
            strokeDasharray="none"
            strokeLinecap="butt"
            strokeLinejoin="round"
            strokeOpacity="0.995"
            strokeWidth="1"
            d="M264.105 129.882c-24.978-.028-50.493.59-76.524 1.438-28.962.942-36.667 15.153-37.37 34.34a84.237 84.237 0 00-.06 3.09v44.656c0 2.799.1 5.415.319 7.865.43 4.811 1.337 8.962 2.891 12.532 1.717 3.942 4.228 7.176 7.789 9.79 5.168 3.797 12.535 6.302 22.873 7.808 46.12 6.718 90.706 4.037 136.911-.094 12.287-1.099 20.864-3.534 26.713-7.713 3.597-2.57 6.16-5.803 7.917-9.79 2.361-5.357 3.272-12.076 3.272-20.399V168.75c0-9.92-1.789-18.546-6.892-25.026-5.564-7.066-15.07-11.578-30.539-12.404-18.769-1.003-37.872-1.416-57.3-1.438z"
            display="inline"
            opacity="1"
            paintOrder="markers fill stroke"
          ></path>
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse">
          <path
            fill="#cb7d71"
            fillOpacity="1"
            fillRule="evenodd"
            stroke="none"
            strokeDasharray="none"
            strokeLinecap="butt"
            strokeLinejoin="round"
            strokeOpacity="0.995"
            strokeWidth="2.2"
            d="M189.134 62.172c-17.713 7.168-45.509 25.584-60.575 49.928-7.696 12.434-2.808 38.302-2.622 58.515.5 54.266 3.65 78.385 3.65 78.385s.15 11.25 22.099 16.019c14.103 3.065 52.645 6.212 78.324 7.427 14.285.676 24.52.132 24.52.132s10.236.544 24.52-.132c25.68-1.215 64.222-4.362 78.326-7.427 21.949-4.77 22.099-16.02 22.099-16.02s3.15-24.118 3.65-78.384c.186-20.213 5.074-46.081-2.622-58.515-15.067-24.344-42.863-42.76-60.576-49.928-41.523-14.609-91.116-15.157-130.793 0z"
            display="inline"
            opacity="1"
            paintOrder="markers fill stroke"
          ></path>
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse">
          <path
            fill="#cb7d71"
            fillOpacity="1"
            fillRule="evenodd"
            stroke="none"
            strokeDasharray="none"
            strokeLinecap="butt"
            strokeLinejoin="round"
            strokeOpacity="0.995"
            strokeWidth="2.2"
            d="M189.134 62.172c-17.713 7.168-45.509 25.584-60.575 49.928-7.696 12.434-2.808 38.302-2.622 58.515.5 54.266 3.65 78.385 3.65 78.385s.15 11.25 22.099 16.019c14.103 3.065 52.645 6.212 78.324 7.427 14.285.676 24.52.132 24.52.132s10.236.544 24.52-.132c25.68-1.215 64.222-4.362 78.326-7.427 21.949-4.77 22.099-16.02 22.099-16.02s3.15-24.118 3.65-78.384c.186-20.213 5.074-46.081-2.622-58.515-15.067-24.344-42.863-42.76-60.576-49.928-41.523-14.609-91.116-15.157-130.793 0z"
            display="inline"
            opacity="1"
            paintOrder="markers fill stroke"
          ></path>
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse">
          <path
            fill="#b56053"
            fillOpacity="1"
            fillRule="evenodd"
            stroke="none"
            strokeDasharray="none"
            strokeLinecap="butt"
            strokeLinejoin="round"
            strokeOpacity="0.995"
            strokeWidth="2.2"
            d="M125.237 145.65s-13.11.659-18.3 9.18c-10.849 17.819-11.499 63.806 3.95 76.495 7.394 6.072 18.35 4.365 18.35 4.365z"
            paintOrder="markers fill stroke"
          ></path>
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse">
          <path
            fill="#b56053"
            fillOpacity="1"
            fillRule="evenodd"
            stroke="none"
            strokeDasharray="none"
            strokeLinecap="butt"
            strokeLinejoin="round"
            strokeOpacity="0.995"
            strokeWidth="2.2"
            d="M125.237 145.65s-13.11.659-18.3 9.18c-10.849 17.819-11.499 63.806 3.95 76.495 7.394 6.072 18.35 4.365 18.35 4.365z"
            paintOrder="markers fill stroke"
          ></path>
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse">
          <path
            fill="#b56053"
            fillOpacity="1"
            fillRule="evenodd"
            stroke="none"
            strokeDasharray="none"
            strokeLinecap="butt"
            strokeLinejoin="round"
            strokeOpacity="1"
            strokeWidth="2"
            d="M106.1 347.38s-10.344-2.844-14.897 1.338c-6.931 6.367-8.215 18.421-8.215 18.421s-6.166 23.584 2.439 25.197c8.604 1.614 14.041 1.614 15.296-4.194 1.255-5.808 3.167-12.261 3.167-12.261s5.915-.86 9.74.645c3.823 1.506 7.588 5.916 7.588 5.916-1.544 3.714-2.14 4.925-4.624 10.726-2.473 6.172 2.65 10.16 10.266 11.155 8.645 3.063 17.343-25.04 17.343-25.04.005.01 4.76-9.777-.653-16.272-5.423-6.508-37.45-15.63-37.45-15.63z"
            display="inline"
            paintOrder="markers fill stroke"
          ></path>
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse">
          <path
            fill="#b56053"
            fillOpacity="1"
            fillRule="evenodd"
            stroke="none"
            strokeDasharray="none"
            strokeLinecap="butt"
            strokeLinejoin="round"
            strokeOpacity="1"
            strokeWidth="2"
            d="M106.1 347.38s-10.344-2.844-14.897 1.338c-6.931 6.367-8.215 18.421-8.215 18.421s-6.166 23.584 2.439 25.197c8.604 1.614 14.041 1.614 15.296-4.194 1.255-5.808 3.167-12.261 3.167-12.261s5.915-.86 9.74.645c3.823 1.506 7.588 5.916 7.588 5.916-1.544 3.714-2.14 4.925-4.624 10.726-2.473 6.172 2.65 10.16 10.266 11.155 8.645 3.063 17.343-25.04 17.343-25.04.005.01 4.76-9.777-.653-16.272-5.423-6.508-37.45-15.63-37.45-15.63z"
            display="inline"
            paintOrder="markers fill stroke"
          ></path>
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse">
          <path
            fill="#b56053"
            fillOpacity="1"
            fillRule="evenodd"
            stroke="none"
            strokeDasharray="none"
            strokeLinecap="butt"
            strokeLinejoin="round"
            strokeOpacity="1"
            strokeWidth="2"
            d="M106.1 347.38s-10.344-2.844-14.897 1.338c-6.931 6.367-8.215 18.421-8.215 18.421s-6.166 23.584 2.439 25.197c8.604 1.614 14.041 1.614 15.296-4.194 1.255-5.808 3.167-12.261 3.167-12.261s5.915-.86 9.74.645c3.823 1.506 7.588 5.916 7.588 5.916-1.544 3.714-2.14 4.925-4.624 10.726-2.473 6.172 2.65 10.16 10.266 11.155 8.645 3.063 17.343-25.04 17.343-25.04.005.01 4.76-9.777-.653-16.272-5.423-6.508-37.45-15.63-37.45-15.63z"
            display="inline"
            paintOrder="markers fill stroke"
          ></path>
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse"></clipPath>
        <clipPath clipPathUnits="userSpaceOnUse">
          <path
            fill="#b56053"
            fillOpacity="1"
            fillRule="evenodd"
            stroke="none"
            strokeDasharray="none"
            strokeLinecap="butt"
            strokeLinejoin="round"
            strokeOpacity="1"
            strokeWidth="2"
            d="M106.1 347.38s-10.344-2.844-14.897 1.338c-6.931 6.367-8.962 18.61-8.962 18.61s-5.419 23.395 3.186 25.008c8.604 1.614 14.041 1.614 15.296-4.194 1.255-5.808 3.167-12.261 3.167-12.261s5.915-.86 9.74.645c3.823 1.506 7.588 5.916 7.588 5.916-1.544 3.714-2.14 4.925-4.624 10.726-2.473 6.172 2.65 10.16 10.266 11.155 8.645 3.063 18.165-24.555 18.165-24.555.005.01 3.937-10.262-1.475-16.757-5.423-6.508-37.45-15.63-37.45-15.63z"
            display="inline"
            paintOrder="markers fill stroke"
          ></path>
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse">
          <path
            fill="#b56053"
            fillOpacity="1"
            fillRule="evenodd"
            stroke="none"
            strokeDasharray="none"
            strokeLinecap="butt"
            strokeLinejoin="round"
            strokeOpacity="1"
            strokeWidth="2"
            d="M106.1 347.38s-10.344-2.844-14.897 1.338c-6.931 6.367-8.962 18.61-8.962 18.61s-5.419 23.395 3.186 25.008c8.604 1.614 14.041 1.614 15.296-4.194 1.255-5.808 3.167-12.261 3.167-12.261s5.915-.86 9.74.645c3.823 1.506 7.588 5.916 7.588 5.916-1.544 3.714-2.14 4.925-4.624 10.726-2.473 6.172 2.65 10.16 10.266 11.155 8.645 3.063 18.165-24.555 18.165-24.555.005.01 3.937-10.262-1.475-16.757-5.423-6.508-37.45-15.63-37.45-15.63z"
            display="inline"
            paintOrder="markers fill stroke"
          ></path>
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse">
          <path
            fill="#cb7d71"
            fillOpacity="1"
            fillRule="evenodd"
            stroke="none"
            strokeDasharray="none"
            strokeLinecap="butt"
            strokeLinejoin="round"
            strokeOpacity="1"
            strokeWidth="2"
            d="M177.985 261.77s-58.736 46.139-68.296 66.251c-9.56 20.112-3.824 24.952 6.872 29.469 10.695 4.517 27.776-.29 33.247-3.61 19.798-12.01 40.486-88.454 40.486-88.454z"
            display="inline"
            paintOrder="markers fill stroke"
          ></path>
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse">
          <path
            fill="#b56053"
            fillOpacity="1"
            fillRule="evenodd"
            stroke="none"
            strokeDasharray="none"
            strokeLinecap="butt"
            strokeLinejoin="round"
            strokeOpacity="0.649"
            strokeWidth="1.3"
            d="M189.29 265.706s-36.852 72.356-39.083 89.086c0 0-2.477 9.56 5.437 20.075 6.734 8.947 30.303 24.376 52.51 37.385 22.888 13.408 44.767 13.17 46.204 13.14 1.434.03 23.315.269 46.204-13.14 22.207-13.009 45.775-28.438 52.51-37.385 7.914-10.515 5.436-20.075 5.436-20.075-2.23-16.73-39.082-89.086-39.082-89.086l-65.068.75z"
            paintOrder="markers fill stroke"
          ></path>
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse">
          <path
            fill="#cb7d71"
            fillOpacity="1"
            fillRule="evenodd"
            stroke="none"
            strokeDasharray="none"
            strokeLinecap="butt"
            strokeLinejoin="round"
            strokeOpacity="0.995"
            strokeWidth="2"
            d="M150.296 365.697s-1.435 29.22 1.629 50.464c3.14 21.777 11.67 37.815 20.969 42.559 10.876 5.549 45.398 10.784 59.125 6.962 8.416-2.343 12.103-4.995 14.745-25.65 1.84-14.39 1.832-17.694 1.832-17.694s-45.69-11.83-98.3-56.64z"
            paintOrder="markers fill stroke"
          ></path>
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse">
          <path
            fill="#b56053"
            fillOpacity="0.974"
            fillRule="evenodd"
            stroke="none"
            strokeDasharray="none"
            strokeLinecap="butt"
            strokeLinejoin="round"
            strokeOpacity="0.995"
            strokeWidth="2.063"
            d="M206.15 463.584c2.52 9.722 10.217 18.125 14.393 18.132 4.064-.437 9.458-8.159 11.712-17.717z"
            paintOrder="markers fill stroke"
          ></path>
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse">
          <path
            fill="#b56053"
            fillOpacity="1"
            fillRule="evenodd"
            stroke="none"
            strokeDasharray="none"
            strokeLinecap="butt"
            strokeLinejoin="round"
            strokeOpacity="1"
            strokeWidth="2"
            d="M398.05 347.38s10.344-2.844 14.897 1.338c6.932 6.367 8.962 18.61 8.962 18.61s5.419 23.395-3.185 25.008c-8.605 1.614-14.042 1.614-15.297-4.194-1.254-5.808-3.166-12.261-3.166-12.261s-5.916-.86-9.74.645c-3.824 1.506-7.588 5.916-7.588 5.916 1.543 3.714 2.139 4.925 4.623 10.726 2.473 6.172-2.649 10.16-10.266 11.155-10.89-.117-18.164-24.555-18.164-24.555-.005.01-3.938-10.262 1.474-16.757 5.423-6.508 37.45-15.63 37.45-15.63z"
            clipPath="none"
            display="inline"
            paintOrder="markers fill stroke"
          ></path>
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse">
          <path
            fill="#b56053"
            fillOpacity="1"
            fillRule="evenodd"
            stroke="none"
            strokeDasharray="none"
            strokeLinecap="butt"
            strokeLinejoin="round"
            strokeOpacity="1"
            strokeWidth="2"
            d="M398.05 347.38s10.344-2.844 14.897 1.338c6.932 6.367 8.962 18.61 8.962 18.61s5.419 23.395-3.185 25.008c-8.605 1.614-14.042 1.614-15.297-4.194-1.254-5.808-3.166-12.261-3.166-12.261s-5.916-.86-9.74.645c-3.824 1.506-7.588 5.916-7.588 5.916 1.543 3.714 2.139 4.925 4.623 10.726 2.473 6.172-2.649 10.16-10.266 11.155-10.89-.117-18.164-24.555-18.164-24.555-.005.01-3.938-10.262 1.474-16.757 5.423-6.508 37.45-15.63 37.45-15.63z"
            clipPath="none"
            display="inline"
            paintOrder="markers fill stroke"
          ></path>
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse">
          <path
            fill="#cb7d71"
            fillOpacity="1"
            fillRule="evenodd"
            stroke="none"
            strokeDasharray="none"
            strokeLinecap="butt"
            strokeLinejoin="round"
            strokeOpacity="0.995"
            strokeWidth="2.2"
            d="M189.663 62.172c-17.713 7.168-45.509 25.584-60.575 49.928-7.696 12.434-2.808 38.302-2.622 58.515.5 54.266 3.65 78.385 3.65 78.385s.15 11.25 22.099 16.019c14.104 3.065 52.645 6.212 78.324 7.427 14.285.676 24.52.132 24.52.132s10.236.544 24.52-.132c25.68-1.215 64.222-4.362 78.326-7.427 21.949-4.77 22.099-16.02 22.099-16.02s3.15-24.118 3.65-78.384c.186-20.213 5.074-46.081-2.622-58.515-15.067-24.344-42.863-42.76-60.576-49.928-41.523-14.609-91.116-15.157-130.793 0z"
            display="inline"
            paintOrder="markers fill stroke"
          ></path>
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse">
          <path
            fill="#a17dd2"
            fillRule="evenodd"
            strokeLinejoin="round"
            strokeOpacity="0.995"
            strokeWidth="1.481"
            d="M164.143 137.703l8.804-5.023 9.434 5.023 2.515 8.517-2.515 7.667-9.434 4.744-8.804-4.744-3.144-8.043z"
            paintOrder="markers fill stroke"
          ></path>
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse">
          <path
            fill="#a17dd2"
            fillRule="evenodd"
            strokeLinejoin="round"
            strokeOpacity="0.995"
            strokeWidth="0.776"
            d="M112.439 145.94l8.084 4.61-.962 8.005-7.369-3.274z"
            paintOrder="markers fill stroke"
          ></path>
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse">
          <path
            fill="#a17dd2"
            fillRule="evenodd"
            strokeLinejoin="round"
            strokeOpacity="0.995"
            strokeWidth="0.776"
            d="M112.439 145.94l8.084 4.61-.962 8.005-7.369-3.274z"
            paintOrder="markers fill stroke"
          ></path>
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse">
          <path
            fill="#a17dd2"
            fillRule="evenodd"
            strokeLinejoin="round"
            strokeOpacity="0.995"
            strokeWidth="1.505"
            d="M173.108 132.68l-9.275 4.977-3.248 8.14 3.248 8.044 9.367 4.468 9.466-4.468 2.598-7.667-2.598-8.517z"
            paintOrder="markers fill stroke"
          ></path>
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse">
          <path
            fill="#e7a54b"
            fillOpacity="1"
            fillRule="evenodd"
            stroke="none"
            strokeLinejoin="round"
            strokeOpacity="1"
            strokeWidth="1.3"
            d="M173.035 132.718l.091 25.652-9.332-4.551-3.264-8.091 3.31-8.137z"
            paintOrder="markers fill stroke"
          ></path>
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse">
          <path
            fill="#e7a54b"
            fillOpacity="1"
            fillRule="evenodd"
            stroke="none"
            strokeLinejoin="round"
            strokeOpacity="1"
            strokeWidth="1.3"
            d="M173.035 132.718l.091 25.652-9.332-4.551-3.264-8.091 3.31-8.137z"
            paintOrder="markers fill stroke"
          ></path>
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse">
          <path
            fill="#fbd574"
            fillOpacity="1"
            fillRule="evenodd"
            stroke="none"
            strokeLinejoin="round"
            strokeOpacity="0.995"
            strokeWidth="1.3"
            d="M96.684 123.366l6.82 50.767c23.032 4.96 45.268 6.528 69.6 6.808l-.092-96.843-36.915 57.636z"
            paintOrder="markers fill stroke"
          ></path>
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse">
          <path
            fill="#fbd574"
            fillOpacity="1"
            fillRule="evenodd"
            stroke="none"
            strokeLinejoin="round"
            strokeOpacity="0.995"
            strokeWidth="1.288"
            d="M97.828 123.366l6.698 50.767c22.62 4.96 44.457 6.528 68.353 6.808l-.09-96.843-36.254 57.636z"
            paintOrder="markers fill stroke"
          ></path>
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse">
          <path
            fill="#fbd574"
            fillOpacity="1"
            fillRule="evenodd"
            stroke="none"
            strokeLinejoin="round"
            strokeOpacity="1"
            strokeWidth="1.3"
            d="M101.865 122.37c12.814 6.438 25.824 12.876 39.687 19.314l31.618-57.811v97.102c-30.412.127-55.28-1.594-69.85-6.747z"
            paintOrder="markers fill stroke"
          ></path>
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse">
          <path
            fill="#936bcc"
            fillOpacity="1"
            fillRule="evenodd"
            stroke="none"
            strokeLinejoin="round"
            strokeOpacity="1"
            strokeWidth="1.3"
            d="M175.43 140.865l-11.149 6.437-11.148-6.437v-12.873l11.148-6.436 11.149 6.436z"
            paintOrder="markers fill stroke"
            transform="translate(8.845 9.306)"
          ></path>
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse"></clipPath>
        <clipPath clipPathUnits="userSpaceOnUse">
          <path
            fill="#b56053"
            fillOpacity="0.974"
            fillRule="evenodd"
            stroke="none"
            strokeDasharray="none"
            strokeLinecap="butt"
            strokeLinejoin="round"
            strokeOpacity="0.995"
            strokeWidth="2.063"
            d="M206.15 463.584c2.52 9.722 10.217 18.125 14.393 18.132 4.064-.437 9.458-8.159 11.712-17.717z"
            paintOrder="markers fill stroke"
          ></path>
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse">
          <path
            fill="#cb7d71"
            fillOpacity="1"
            fillRule="evenodd"
            stroke="none"
            strokeDasharray="none"
            strokeLinecap="butt"
            strokeLinejoin="round"
            strokeOpacity="0.995"
            strokeWidth="2"
            d="M150.296 365.697s-1.435 29.22 1.629 50.464c3.14 21.777 11.67 37.815 20.969 42.559 10.876 5.549 45.398 10.784 59.125 6.962 8.416-2.343 12.103-4.995 14.745-25.65 1.84-14.39 1.832-17.694 1.832-17.694s-45.69-11.83-98.3-56.64z"
            paintOrder="markers fill stroke"
          ></path>
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse">
          <path
            fill="#b56053"
            fillOpacity="1"
            fillRule="evenodd"
            stroke="none"
            strokeDasharray="none"
            strokeLinecap="butt"
            strokeLinejoin="round"
            strokeOpacity="1"
            strokeWidth="2"
            d="M106.1 347.38s-10.344-2.844-14.897 1.338c-6.931 6.367-8.215 18.421-8.215 18.421s-6.166 23.584 2.439 25.197c8.604 1.614 14.041 1.614 15.296-4.194 1.255-5.808 3.167-12.261 3.167-12.261s5.915-.86 9.74.645c3.823 1.506 7.588 5.916 7.588 5.916-1.544 3.714-2.14 4.925-4.624 10.726-2.473 6.172 2.65 10.16 10.266 11.155 8.645 3.063 17.343-25.04 17.343-25.04.005.01 4.76-9.777-.653-16.272-5.423-6.508-37.45-15.63-37.45-15.63z"
            display="inline"
            paintOrder="markers fill stroke"
          ></path>
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse">
          <path
            fill="#b56053"
            fillOpacity="1"
            fillRule="evenodd"
            stroke="none"
            strokeDasharray="none"
            strokeLinecap="butt"
            strokeLinejoin="round"
            strokeOpacity="1"
            strokeWidth="2"
            d="M106.1 347.38s-10.344-2.844-14.897 1.338c-6.931 6.367-8.215 18.421-8.215 18.421s-6.166 23.584 2.439 25.197c8.604 1.614 14.041 1.614 15.296-4.194 1.255-5.808 3.167-12.261 3.167-12.261s5.915-.86 9.74.645c3.823 1.506 7.588 5.916 7.588 5.916-1.544 3.714-2.14 4.925-4.624 10.726-2.473 6.172 2.65 10.16 10.266 11.155 8.645 3.063 17.343-25.04 17.343-25.04.005.01 4.76-9.777-.653-16.272-5.423-6.508-37.45-15.63-37.45-15.63z"
            display="inline"
            paintOrder="markers fill stroke"
          ></path>
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse">
          <path
            fill="#b56053"
            fillOpacity="1"
            fillRule="evenodd"
            stroke="none"
            strokeDasharray="none"
            strokeLinecap="butt"
            strokeLinejoin="round"
            strokeOpacity="1"
            strokeWidth="2"
            d="M398.05 347.38s10.344-2.844 14.897 1.338c6.932 6.367 8.962 18.61 8.962 18.61s5.419 23.395-3.185 25.008c-8.605 1.614-14.042 1.614-15.297-4.194-1.254-5.808-3.166-12.261-3.166-12.261s-5.916-.86-9.74.645c-3.824 1.506-7.588 5.916-7.588 5.916 1.543 3.714 2.139 4.925 4.623 10.726 2.473 6.172-2.649 10.16-10.266 11.155-10.89-.117-18.164-24.555-18.164-24.555-.005.01-3.938-10.262 1.474-16.757 5.423-6.508 37.45-15.63 37.45-15.63z"
            clipPath="none"
            display="inline"
            paintOrder="markers fill stroke"
          ></path>
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse">
          <path
            fill="#b56053"
            fillOpacity="1"
            fillRule="evenodd"
            stroke="none"
            strokeDasharray="none"
            strokeLinecap="butt"
            strokeLinejoin="round"
            strokeOpacity="1"
            strokeWidth="2"
            d="M106.1 347.38s-10.344-2.844-14.897 1.338c-6.931 6.367-8.962 18.61-8.962 18.61s-5.419 23.395 3.186 25.008c8.604 1.614 14.041 1.614 15.296-4.194 1.255-5.808 3.167-12.261 3.167-12.261s5.915-.86 9.74.645c3.823 1.506 7.588 5.916 7.588 5.916-1.544 3.714-2.14 4.925-4.624 10.726-2.473 6.172 2.65 10.16 10.266 11.155 8.645 3.063 18.165-24.555 18.165-24.555.005.01 3.937-10.262-1.475-16.757-5.423-6.508-37.45-15.63-37.45-15.63z"
            display="inline"
            paintOrder="markers fill stroke"
          ></path>
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse">
          <path
            fill="#b56053"
            fillOpacity="1"
            fillRule="evenodd"
            stroke="none"
            strokeDasharray="none"
            strokeLinecap="butt"
            strokeLinejoin="round"
            strokeOpacity="1"
            strokeWidth="2"
            d="M398.05 347.38s10.344-2.844 14.897 1.338c6.932 6.367 8.962 18.61 8.962 18.61s5.419 23.395-3.185 25.008c-8.605 1.614-14.042 1.614-15.297-4.194-1.254-5.808-3.166-12.261-3.166-12.261s-5.916-.86-9.74.645c-3.824 1.506-7.588 5.916-7.588 5.916 1.543 3.714 2.139 4.925 4.623 10.726 2.473 6.172-2.649 10.16-10.266 11.155-10.89-.117-18.164-24.555-18.164-24.555-.005.01-3.938-10.262 1.474-16.757 5.423-6.508 37.45-15.63 37.45-15.63z"
            clipPath="none"
            display="inline"
            paintOrder="markers fill stroke"
          ></path>
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse">
          <path
            fill="#cb7d71"
            fillOpacity="1"
            fillRule="evenodd"
            stroke="none"
            strokeDasharray="none"
            strokeLinecap="butt"
            strokeLinejoin="round"
            strokeOpacity="1"
            strokeWidth="2"
            d="M177.985 261.77s-58.736 46.139-68.296 66.251c-9.56 20.112-3.824 24.952 6.872 29.469 10.695 4.517 27.776-.29 33.247-3.61 19.798-12.01 40.486-88.454 40.486-88.454z"
            display="inline"
            paintOrder="markers fill stroke"
          ></path>
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse">
          <path
            fill="#b56053"
            fillOpacity="0.974"
            fillRule="evenodd"
            stroke="none"
            strokeDasharray="none"
            strokeLinecap="butt"
            strokeLinejoin="round"
            strokeOpacity="0.995"
            strokeWidth="2.063"
            d="M206.15 463.584c2.52 9.722 10.217 18.125 14.393 18.132 4.064-.437 9.458-8.159 11.712-17.717z"
            paintOrder="markers fill stroke"
          ></path>
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse">
          <path
            fill="#cb7d71"
            fillOpacity="1"
            fillRule="evenodd"
            stroke="none"
            strokeDasharray="none"
            strokeLinecap="butt"
            strokeLinejoin="round"
            strokeOpacity="0.995"
            strokeWidth="2"
            d="M150.296 365.697s-1.435 29.22 1.629 50.464c3.14 21.777 11.67 37.815 20.969 42.559 10.876 5.549 45.398 10.784 59.125 6.962 8.416-2.343 12.103-4.995 14.745-25.65 1.84-14.39 1.832-17.694 1.832-17.694s-45.69-11.83-98.3-56.64z"
            paintOrder="markers fill stroke"
          ></path>
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse">
          <path
            fill="#b56053"
            fillOpacity="1"
            fillRule="evenodd"
            stroke="none"
            strokeDasharray="none"
            strokeLinecap="butt"
            strokeLinejoin="round"
            strokeOpacity="1"
            strokeWidth="2"
            d="M106.1 347.38s-10.344-2.844-14.897 1.338c-6.931 6.367-8.215 18.421-8.215 18.421s-6.166 23.584 2.439 25.197c8.604 1.614 14.041 1.614 15.296-4.194 1.255-5.808 3.167-12.261 3.167-12.261s5.915-.86 9.74.645c3.823 1.506 7.588 5.916 7.588 5.916-1.544 3.714-2.14 4.925-4.624 10.726-2.473 6.172 2.65 10.16 10.266 11.155 8.645 3.063 17.343-25.04 17.343-25.04.005.01 4.76-9.777-.653-16.272-5.423-6.508-37.45-15.63-37.45-15.63z"
            display="inline"
            paintOrder="markers fill stroke"
          ></path>
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse">
          <path
            fill="#b56053"
            fillOpacity="1"
            fillRule="evenodd"
            stroke="none"
            strokeDasharray="none"
            strokeLinecap="butt"
            strokeLinejoin="round"
            strokeOpacity="1"
            strokeWidth="2"
            d="M106.1 347.38s-10.344-2.844-14.897 1.338c-6.931 6.367-8.215 18.421-8.215 18.421s-6.166 23.584 2.439 25.197c8.604 1.614 14.041 1.614 15.296-4.194 1.255-5.808 3.167-12.261 3.167-12.261s5.915-.86 9.74.645c3.823 1.506 7.588 5.916 7.588 5.916-1.544 3.714-2.14 4.925-4.624 10.726-2.473 6.172 2.65 10.16 10.266 11.155 8.645 3.063 17.343-25.04 17.343-25.04.005.01 4.76-9.777-.653-16.272-5.423-6.508-37.45-15.63-37.45-15.63z"
            display="inline"
            paintOrder="markers fill stroke"
          ></path>
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse">
          <path
            fill="#b56053"
            fillOpacity="1"
            fillRule="evenodd"
            stroke="none"
            strokeDasharray="none"
            strokeLinecap="butt"
            strokeLinejoin="round"
            strokeOpacity="1"
            strokeWidth="2"
            d="M398.05 347.38s10.344-2.844 14.897 1.338c6.932 6.367 8.962 18.61 8.962 18.61s5.419 23.395-3.185 25.008c-8.605 1.614-14.042 1.614-15.297-4.194-1.254-5.808-3.166-12.261-3.166-12.261s-5.916-.86-9.74.645c-3.824 1.506-7.588 5.916-7.588 5.916 1.543 3.714 2.139 4.925 4.623 10.726 2.473 6.172-2.649 10.16-10.266 11.155-10.89-.117-18.164-24.555-18.164-24.555-.005.01-3.938-10.262 1.474-16.757 5.423-6.508 37.45-15.63 37.45-15.63z"
            clipPath="none"
            display="inline"
            paintOrder="markers fill stroke"
          ></path>
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse"></clipPath>
        <clipPath clipPathUnits="userSpaceOnUse">
          <path
            fill="#b56053"
            fillOpacity="1"
            fillRule="evenodd"
            stroke="none"
            strokeDasharray="none"
            strokeLinecap="butt"
            strokeLinejoin="round"
            strokeOpacity="1"
            strokeWidth="2"
            d="M106.1 347.38s-10.344-2.844-14.897 1.338c-6.931 6.367-8.962 18.61-8.962 18.61s-5.419 23.395 3.186 25.008c8.604 1.614 14.041 1.614 15.296-4.194 1.255-5.808 3.167-12.261 3.167-12.261s5.915-.86 9.74.645c3.823 1.506 7.588 5.916 7.588 5.916-1.544 3.714-2.14 4.925-4.624 10.726-2.473 6.172 2.65 10.16 10.266 11.155 8.645 3.063 18.165-24.555 18.165-24.555.005.01 3.937-10.262-1.475-16.757-5.423-6.508-37.45-15.63-37.45-15.63z"
            display="inline"
            paintOrder="markers fill stroke"
          ></path>
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse">
          <path
            fill="#b56053"
            fillOpacity="1"
            fillRule="evenodd"
            stroke="none"
            strokeDasharray="none"
            strokeLinecap="butt"
            strokeLinejoin="round"
            strokeOpacity="1"
            strokeWidth="2"
            d="M398.05 347.38s10.344-2.844 14.897 1.338c6.932 6.367 8.962 18.61 8.962 18.61s5.419 23.395-3.185 25.008c-8.605 1.614-14.042 1.614-15.297-4.194-1.254-5.808-3.166-12.261-3.166-12.261s-5.916-.86-9.74.645c-3.824 1.506-7.588 5.916-7.588 5.916 1.543 3.714 2.139 4.925 4.623 10.726 2.473 6.172-2.649 10.16-10.266 11.155-10.89-.117-18.164-24.555-18.164-24.555-.005.01-3.938-10.262 1.474-16.757 5.423-6.508 37.45-15.63 37.45-15.63z"
            clipPath="none"
            display="inline"
            paintOrder="markers fill stroke"
          ></path>
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse">
          <path
            fill="#cb7d71"
            fillOpacity="1"
            fillRule="evenodd"
            stroke="none"
            strokeDasharray="none"
            strokeLinecap="butt"
            strokeLinejoin="round"
            strokeOpacity="1"
            strokeWidth="2"
            d="M177.985 261.77s-58.736 46.139-68.296 66.251c-9.56 20.112-3.824 24.952 6.872 29.469 10.695 4.517 27.776-.29 33.247-3.61 19.798-12.01 40.486-88.454 40.486-88.454z"
            display="inline"
            paintOrder="markers fill stroke"
          ></path>
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse">
          <path
            fill="#b56053"
            fillOpacity="1"
            fillRule="evenodd"
            stroke="none"
            strokeDasharray="none"
            strokeLinecap="butt"
            strokeLinejoin="round"
            strokeOpacity="0.649"
            strokeWidth="1.3"
            d="M189.29 265.706s-36.852 72.356-39.083 89.086c0 0-2.477 9.56 5.437 20.075 6.734 8.947 30.303 24.376 52.51 37.385 22.888 13.408 44.767 13.17 46.204 13.14 1.434.03 23.315.269 46.204-13.14 22.207-13.009 45.775-28.438 52.51-37.385 7.914-10.515 5.436-20.075 5.436-20.075-2.23-16.73-39.082-89.086-39.082-89.086l-65.068.75z"
            paintOrder="markers fill stroke"
          ></path>
        </clipPath>
        <clipPath id="clipPath41389-0" clipPathUnits="userSpaceOnUse">
          <path
            fill="#cb7d71"
            fillOpacity="1"
            fillRule="evenodd"
            stroke="none"
            strokeDasharray="none"
            strokeLinecap="butt"
            strokeLinejoin="round"
            strokeOpacity="0.995"
            strokeWidth="2.2"
            d="M189.663 62.172c-17.713 7.168-45.509 25.584-60.575 49.928-7.696 12.434-2.808 38.302-2.622 58.515.5 54.266 3.65 78.385 3.65 78.385s.15 11.25 22.099 16.019c14.104 3.065 52.645 6.212 78.324 7.427 14.285.676 24.52.132 24.52.132s10.236.544 24.52-.132c25.68-1.215 64.222-4.362 78.326-7.427 21.949-4.77 22.099-16.02 22.099-16.02s3.15-24.118 3.65-78.384c.186-20.213 5.074-46.081-2.622-58.515-15.067-24.344-42.863-42.76-60.576-49.928-41.523-14.609-91.116-15.157-130.793 0z"
            display="inline"
            paintOrder="markers fill stroke"
          ></path>
        </clipPath>
        <clipPath id="clipPath20032-5" clipPathUnits="userSpaceOnUse">
          <path
            fill="#cb7d71"
            fillOpacity="1"
            fillRule="evenodd"
            stroke="none"
            strokeDasharray="none"
            strokeLinecap="butt"
            strokeLinejoin="round"
            strokeOpacity="0.995"
            strokeWidth="2.2"
            d="M189.134 62.172c-17.713 7.168-45.509 25.584-60.575 49.928-7.696 12.434-2.808 38.302-2.622 58.515.5 54.266 3.65 78.385 3.65 78.385s.15 11.25 22.099 16.019c14.103 3.065 52.645 6.212 78.324 7.427 14.285.676 24.52.132 24.52.132s10.236.544 24.52-.132c25.68-1.215 64.222-4.362 78.326-7.427 21.949-4.77 22.099-16.02 22.099-16.02s3.15-24.118 3.65-78.384c.186-20.213 5.074-46.081-2.622-58.515-15.067-24.344-42.863-42.76-60.576-49.928-41.523-14.609-91.116-15.157-130.793 0z"
            display="inline"
            opacity="1"
            paintOrder="markers fill stroke"
          ></path>
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse">
          <path
            fill="#465d59"
            fillOpacity="1"
            fillRule="evenodd"
            stroke="none"
            strokeDasharray="none"
            strokeLinecap="butt"
            strokeLinejoin="round"
            strokeOpacity="0.995"
            strokeWidth="1"
            d="M264.105 129.882c-24.978-.028-50.493.59-76.524 1.438-28.962.942-36.667 15.153-37.37 34.34a84.237 84.237 0 00-.06 3.09v44.656c0 2.799.1 5.415.319 7.865.43 4.811 1.337 8.962 2.891 12.532 1.717 3.942 4.228 7.176 7.789 9.79 5.168 3.797 12.535 6.302 22.873 7.808 46.12 6.718 90.706 4.037 136.911-.094 12.287-1.099 20.864-3.534 26.713-7.713 3.597-2.57 6.16-5.803 7.917-9.79 2.361-5.357 3.272-12.076 3.272-20.399V168.75c0-9.92-1.789-18.546-6.892-25.026-5.564-7.066-15.07-11.578-30.539-12.404-18.769-1.003-37.872-1.416-57.3-1.438z"
            display="inline"
            opacity="1"
            paintOrder="markers fill stroke"
          ></path>
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse">
          <path
            fill="#b56053"
            fillOpacity="1"
            fillRule="evenodd"
            stroke="none"
            strokeDasharray="none"
            strokeLinecap="butt"
            strokeLinejoin="round"
            strokeOpacity="0.995"
            strokeWidth="2.2"
            d="M125.237 145.65s-13.11.659-18.3 9.18c-10.849 17.819-11.499 63.806 3.95 76.495 7.394 6.072 18.35 4.365 18.35 4.365z"
            paintOrder="markers fill stroke"
          ></path>
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse">
          <path
            fill="#b56053"
            fillOpacity="1"
            fillRule="evenodd"
            stroke="none"
            strokeDasharray="none"
            strokeLinecap="butt"
            strokeLinejoin="round"
            strokeOpacity="0.995"
            strokeWidth="2.2"
            d="M125.237 145.65s-13.11.659-18.3 9.18c-10.849 17.819-11.499 63.806 3.95 76.495 7.394 6.072 18.35 4.365 18.35 4.365z"
            paintOrder="markers fill stroke"
          ></path>
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse">
          <path
            fill="#b56053"
            fillOpacity="1"
            fillRule="evenodd"
            stroke="none"
            strokeDasharray="none"
            strokeLinecap="butt"
            strokeLinejoin="round"
            strokeOpacity="0.995"
            strokeWidth="2.2"
            d="M125.237 145.65s-13.11.659-18.3 9.18c-10.849 17.819-11.499 63.806 3.95 76.495 7.394 6.072 18.35 4.365 18.35 4.365z"
            paintOrder="markers fill stroke"
          ></path>
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse">
          <path
            fill="#b56053"
            fillOpacity="1"
            fillRule="evenodd"
            stroke="none"
            strokeDasharray="none"
            strokeLinecap="butt"
            strokeLinejoin="round"
            strokeOpacity="0.995"
            strokeWidth="2.2"
            d="M125.237 145.65s-13.11.659-18.3 9.18c-10.849 17.819-11.499 63.806 3.95 76.495 7.394 6.072 18.35 4.365 18.35 4.365z"
            paintOrder="markers fill stroke"
          ></path>
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse">
          <path
            fill="#465d59"
            fillOpacity="1"
            fillRule="evenodd"
            stroke="none"
            strokeDasharray="none"
            strokeLinecap="butt"
            strokeLinejoin="round"
            strokeOpacity="0.995"
            strokeWidth="0.987"
            d="M110.221 306.257c1.012 41.31 37.32 62.334 93.234 62.334 46.425 0 86.922-23.086 87.16-63.508.218-36.921-14.274-61.565-40.887-70.438-27.465-9.159-70.437-9.4-97.577-.82-28.387 8.975-42.77 38.15-41.93 72.432z"
            clipPath="none"
            display="inline"
            opacity="1"
            paintOrder="markers fill stroke"
          ></path>
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse"></clipPath>
        <clipPath clipPathUnits="userSpaceOnUse"></clipPath>
        <clipPath clipPathUnits="userSpaceOnUse"></clipPath>
        <clipPath clipPathUnits="userSpaceOnUse"></clipPath>
        <clipPath clipPathUnits="userSpaceOnUse"></clipPath>
        <clipPath clipPathUnits="userSpaceOnUse"></clipPath>
        <clipPath clipPathUnits="userSpaceOnUse"></clipPath>
        <clipPath clipPathUnits="userSpaceOnUse"></clipPath>
        <clipPath clipPathUnits="userSpaceOnUse"></clipPath>
        <clipPath clipPathUnits="userSpaceOnUse">
          <ellipse
            cx="200"
            cy="414.143"
            fill="#cb7d71"
            fillOpacity="1"
            stroke="none"
            strokeDasharray="none"
            strokeLinecap="square"
            strokeOpacity="1"
            strokeWidth="12"
            opacity="1"
            rx="108.765"
            ry="60.359"
          ></ellipse>
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse">
          <path
            fill="#b56053"
            fillOpacity="1"
            stroke="none"
            strokeLinecap="square"
            strokeWidth="1"
            d="M80.289 384.33c0 15.52 2.695 36.072-1.902 37.399-7.32 2.112-12.361-18.45-13.733-33.595-1.898-20.954 1.74-53.105 14.579-55.146 19.083-3.033 1.056 35.823 1.056 51.343z"
            opacity="1"
          ></path>
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse">
          <path
            fill="#b56053"
            fillOpacity="1"
            fillRule="evenodd"
            stroke="none"
            strokeDasharray="none"
            strokeLinecap="butt"
            strokeLinejoin="round"
            strokeOpacity="1"
            strokeWidth="2"
            d="M106.1 347.38s-10.344-2.844-14.897 1.338c-6.932 6.367-8.962 18.61-8.962 18.61s-5.419 23.395 3.186 25.008c8.604 1.614 14.041 1.614 15.296-4.194 1.255-5.808 3.167-12.261 3.167-12.261s5.915-.86 9.74.645c3.823 1.506 7.588 5.916 7.588 5.916-1.544 3.714-2.14 4.925-4.624 10.726-2.473 6.172 2.65 10.16 10.266 11.155 10.89-.117 18.165-24.555 18.165-24.555.005.01 3.937-10.262-1.475-16.757-5.423-6.508-37.45-15.63-37.45-15.63z"
            clipPath="none"
            display="inline"
            opacity="1"
            paintOrder="markers fill stroke"
          ></path>
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse">
          <path
            fill="#b56053"
            fillOpacity="1"
            fillRule="evenodd"
            stroke="none"
            strokeDasharray="none"
            strokeLinecap="butt"
            strokeLinejoin="round"
            strokeOpacity="1"
            strokeWidth="2"
            d="M106.1 347.38s-10.344-2.844-14.897 1.338c-6.931 6.367-8.962 18.61-8.962 18.61s-5.419 23.395 3.186 25.008c8.604 1.614 14.041 1.614 15.296-4.194 1.255-5.808 3.167-12.261 3.167-12.261s5.915-.86 9.74.645c3.823 1.506 7.588 5.916 7.588 5.916-1.544 3.714-2.14 4.925-4.624 10.726-2.473 6.172 2.65 10.16 10.266 11.155 10.89-.117 18.165-24.555 18.165-24.555.005.01 3.937-10.262-1.475-16.757-5.423-6.508-37.45-15.63-37.45-15.63z"
            clipPath="none"
            display="inline"
            opacity="1"
            paintOrder="markers fill stroke"
          ></path>
        </clipPath>
      </defs>
      <g
        fill="#fbd574"
        fillOpacity="1"
        stroke="none"
        display="inline"
        opacity="1"
      >
        <g transform="translate(-26.863 -125.631)">
          <path
            fill="#cb7d71"
            fillOpacity="1"
            fillRule="evenodd"
            stroke="none"
            strokeDasharray="none"
            strokeLinecap="butt"
            strokeLinejoin="round"
            strokeOpacity="0.995"
            strokeWidth="2.2"
            d="M134.059 172.352c-17.713 7.168-45.509 25.585-60.575 49.928-7.696 12.434-2.808 38.302-2.622 58.515.5 54.267 3.65 78.385 3.65 78.385s.15 11.25 22.099 16.02c14.104 3.064 52.713 6.212 78.324 7.426 16.756.91 34.335 1.408 47.718.794 25.68-1.215 65.544-5.156 79.648-8.22 21.949-4.77 22.099-16.02 22.099-16.02s3.15-24.118 3.65-78.385c.186-20.213 5.074-46.08-2.622-58.515-15.067-24.343-42.863-42.76-60.576-49.928-41.523-14.608-91.116-15.157-130.793 0z"
            display="inline"
            opacity="1"
            paintOrder="markers fill stroke"
          ></path>
          <g
            clipPath="url(#clipPath41389-0)"
            display="inline"
            opacity="1"
            transform="translate(-55.604 110.18)"
          >
            <path
              fill="#8b372b"
              fillOpacity="1"
              fillRule="evenodd"
              stroke="#8b372b"
              strokeDasharray="none"
              strokeLinecap="butt"
              strokeLinejoin="round"
              strokeOpacity="0.995"
              strokeWidth="2.2"
              d="M173.586 61.537l45.605 70.968"
              className="UnoptimicedTransforms"
              paintOrder="markers fill stroke"
            ></path>
            <path
              fill="#8b372b"
              fillOpacity="1"
              fillRule="evenodd"
              stroke="#8b372b"
              strokeDasharray="none"
              strokeLinecap="butt"
              strokeLinejoin="round"
              strokeOpacity="0.995"
              strokeWidth="2.2"
              d="M337.725 61.008l-45.605 70.968"
              className="UnoptimicedTransforms"
              paintOrder="markers fill stroke"
            ></path>
            <circle
              cx="177.711"
              cy="90.277"
              r="2.5"
              fill="#8b372b"
              fillOpacity="1"
              fillRule="evenodd"
              stroke="#8b372b"
              strokeDasharray="none"
              strokeLinecap="butt"
              strokeLinejoin="round"
              strokeOpacity="0.995"
              strokeWidth="2.2"
              paintOrder="markers fill stroke"
            ></circle>
            <circle
              cx="188.294"
              cy="106.152"
              r="2.5"
              fill="#8b372b"
              fillOpacity="1"
              fillRule="evenodd"
              stroke="#8b372b"
              strokeDasharray="none"
              strokeLinecap="butt"
              strokeLinejoin="round"
              strokeOpacity="0.995"
              strokeWidth="2.2"
              paintOrder="markers fill stroke"
            ></circle>
            <circle
              cx="198.878"
              cy="122.027"
              r="2.5"
              fill="#8b372b"
              fillOpacity="1"
              fillRule="evenodd"
              stroke="#8b372b"
              strokeDasharray="none"
              strokeLinecap="butt"
              strokeLinejoin="round"
              strokeOpacity="0.995"
              strokeWidth="2.2"
              paintOrder="markers fill stroke"
            ></circle>
            <circle
              cx="-333.816"
              cy="90.277"
              r="2.5"
              fill="#8b372b"
              fillOpacity="1"
              fillRule="evenodd"
              stroke="#8b372b"
              strokeDasharray="none"
              strokeLinecap="butt"
              strokeLinejoin="round"
              strokeOpacity="0.995"
              strokeWidth="2.2"
              paintOrder="markers fill stroke"
              transform="scale(-1 1)"
            ></circle>
            <circle
              cx="-323.232"
              cy="106.152"
              r="2.5"
              fill="#8b372b"
              fillOpacity="1"
              fillRule="evenodd"
              stroke="#8b372b"
              strokeDasharray="none"
              strokeLinecap="butt"
              strokeLinejoin="round"
              strokeOpacity="0.995"
              strokeWidth="2.2"
              paintOrder="markers fill stroke"
              transform="scale(-1 1)"
            ></circle>
            <circle
              cx="-312.649"
              cy="122.027"
              r="2.5"
              fill="#8b372b"
              fillOpacity="1"
              fillRule="evenodd"
              stroke="#8b372b"
              strokeDasharray="none"
              strokeLinecap="butt"
              strokeLinejoin="round"
              strokeOpacity="0.995"
              strokeWidth="2.2"
              paintOrder="markers fill stroke"
              transform="scale(-1 1)"
            ></circle>
          </g>
          <path
            fill="#e19488"
            fillOpacity="1"
            fillRule="evenodd"
            stroke="none"
            strokeDasharray="none"
            strokeLinecap="butt"
            strokeLinejoin="round"
            strokeOpacity="0.995"
            strokeWidth="2.2"
            d="M178.426 34.435H330.769V66.203H178.426z"
            clipPath="url(#clipPath20032-5)"
            display="inline"
            opacity="1"
            paintOrder="markers fill stroke"
            transform="translate(-55.075 110.18)"
          ></path>
        </g>
      </g>
    </svg>
  );
}