import { content } from "content/content";
import acc1 from "assets/acc/acc_1.png";
import acc2 from "assets/acc/acc_2.png";
import acc3 from "assets/acc/acc_3.png";
import acc4 from "assets/acc/acc_4.png";
import acc5 from "assets/acc/acc_5.png";
import acc6 from "assets/acc/acc_6.png";
import acc7 from "assets/acc/acc_7.png";
import acc8 from "assets/acc/acc_8.png";
import { animated, useInView } from "@react-spring/web";

export function Accessories() {
    return (
        <div className="flex flex-col items-center w-[100%] gap-6 ">
            <h2 className="text-remiOrange text-2xl">The Accessories</h2>
            <div className="justify-start items-start flex w-[100%">
                <AccessoryRows />
            </div>
        </div>
    );
}

const useDropInSprings = (count) => {
    const viewSprings = [];
    for (let i = 0; i < count; i++) {
        viewSprings.push(
            // eslint-disable-next-line react-hooks/rules-of-hooks
            useInView(
                () => ({
                    from: { opacity: 0, top: "-100px" },
                    to: { opacity: 1, top: "0px" },
                    config: {
                        friction: 12,
                        tension: 75,
                        
                    },
                }),
                {
                    once: true,
                }
            )
        );
    }
    return viewSprings;
};

function AccessoryRows() {
    const accs = [acc1, acc2, acc3, acc4, acc5, acc6, acc7, acc8];
    const springs = useDropInSprings(accs.length);

    const getAccessorySquares = () => {
        let returnedDivs = [];
        for (let i = 0; i < accs.length; i++) {
            let imgSrc = accs[i];
            let [springRef, animSpring] = [...springs[i]];
            returnedDivs.push(
                <animated.div key={imgSrc} ref={springRef} style={{ ...animSpring }} className="relative flex justify-center overflow-clip">
                    <img src={imgSrc} alt="remi_accesory" className="h-full max-h-[32px]"/>
                </animated.div>
            );
        }
        return returnedDivs;
    };

    return (
        <div className="">
            <div className="grid md:grid-cols-3 lg:grid-cols-4 grid-cols-2 gap-32 p-8">{getAccessorySquares()}</div>
            <div className="text-lg mt-12">{content.AccessoryText}</div>
        </div>
    );
}
