import React from "react";
import { NftLicense } from "./NftLicense";
import { ToS } from "./Tos";

export function Terms() {
    const pageNames = {
        TERMS: "terms",
        NFT_LICENSE: "nft_license",
    };

    const [page, setPage] = React.useState(pageNames.TERMS);
    const pageActive = (pageName) => pageName === page;

    return (
        <div className=" flex flex-col w-full justify-center items-center p-8">
            <div className="max-w-6xl w-full">
                <div className="flex gap-8 md:justify-center justify-between mb-8">
                    <div
                        className={
                            "cursor-pointer " +
                            (pageActive(pageNames.TERMS) && "underline")
                        }
                        onClick={() => setPage(pageNames.TERMS)}
                    >
                        Terms And Conditions
                    </div>
                    <div
                        className={
                            "cursor-pointer " +
                            (pageActive(pageNames.NFT_LICENSE) && "underline")
                        }
                        onClick={() => setPage(pageNames.NFT_LICENSE)}
                    >
                        NFT License
                    </div>
                </div>
                <div>{page === pageNames.TERMS ? <ToS /> : <NftLicense />}</div>
            </div>
        </div>
    );
}
