import { content } from "content/content";

export function RemiRoadmap() {

    return (
        <>
            <div className="flex justify-center mb-8">
                <h2 className="text-remiOrange text-2xl">R3M1 Roadmap</h2>
            </div>
            <div>
                <p className="text-lg">{content.RoadmapText}</p>
            </div>{" "}
        </>
    );
}
