import {
    RemiCore,
    RemiCoreAccent,
    RemiFace,
    RemiHead,
    RemiLeftArm,
    RemiLeftEar,
    RemiLegs,
    RemiRightArm,
    RemiRightEar,
    RemiScreen,
    RemiThighs,
} from "./pieces";

import { animated, useSpring } from "@react-spring/web";

export function RemiBuilder() {
    const baseAnimDelay = 2400;
    const useDropdownSpring = (delay = 0, friction = 26, tension = 180) => {
        return useSpring({
            from: { top: "-100%", opacity: 0 },
            to: { top: "0%", opacity: 1 },
            config: {
                friction: friction,
                tension: tension,
            },
            delay: delay,
        });
    };
    const usePopupSpring = (delay = 0, friction = 26, tension = 180) => {
        return useSpring({
            from: { bottom: "-100%", oapcity: 0 },
            to: { bottom: "0%", opacity: 1 },
            config: {
                friction: friction,
                tension: tension,
            },
            delay: delay,
        });
    };
    const useAppearSpring = (delay = 0, friction = 26, tension = 180) => {
        return useSpring({
            from: { opacity: 0 },
            to: { opacity: 1 },
            config: {
                friction: friction,
                tension: tension,
            },
            delay: delay,
        });
    };
    const useSidewaysSpring = (
        direction = "left",
        delay = 0,
        friction = 26,
        tension = 180
    ) => {
        return useSpring({
            from: { [direction === "left" ? "left" : "right"]: "-100%", opacty: 0 },
            to: { [direction === "left" ? "left" : "right"]: "0%", opacity: 1 },
            config: {
                friction: friction,
                tension: tension,
            },
            delay: delay,
        });
    };

    const RemiAnimationComponent = ({ RemiComponent, spring, className }) => (
        <animated.div
            style={{ ...spring }}
            className={`absolute flex h-full w-full ${className}`}
        >
            <RemiComponent />
        </animated.div>
    );

    const legsSpring = useDropdownSpring(baseAnimDelay);
    const thighSpring = useDropdownSpring(baseAnimDelay + 300);
    const coreSpring = useDropdownSpring(baseAnimDelay + 800, 40);
    const rightArmSpring = useSidewaysSpring("right", baseAnimDelay + 400, 46);
    const leftArmSpring = useSidewaysSpring("left", baseAnimDelay + 600, 46);
    const headSpring = useDropdownSpring(baseAnimDelay + 1100, 60);
    const leftEarSpring = useSidewaysSpring("left", baseAnimDelay + 1100, 46);
    const rightEarSpring = useSidewaysSpring("right", baseAnimDelay + 1600, 46);
    const screenSpring = useDropdownSpring(baseAnimDelay + 1400, 50);
    const coreAccentSpring = usePopupSpring(baseAnimDelay + 2300, 28);
    const faceSpring = useAppearSpring(baseAnimDelay + 3200, 5, 5);

    return (
        <div className="relative flex flex-grow overflow-hidden md:max-w-[50%]">
            <RemiAnimationComponent
                RemiComponent={RemiFace}
                spring={faceSpring}
                className="z-20"
            />
            <RemiAnimationComponent
                RemiComponent={RemiCoreAccent}
                spring={coreAccentSpring}
                className="z-10"
            />
            <RemiAnimationComponent
                RemiComponent={RemiScreen}
                spring={screenSpring}
                className="z-10"
            />
            <RemiAnimationComponent
                RemiComponent={RemiRightEar}
                spring={rightEarSpring}
            />
            <RemiAnimationComponent
                RemiComponent={RemiLeftEar}
                spring={leftEarSpring}
            />
            <RemiAnimationComponent
                RemiComponent={RemiHead}
                spring={headSpring}
            />
            <RemiAnimationComponent
                RemiComponent={RemiRightArm}
                spring={rightArmSpring}
            />
            <RemiAnimationComponent
                RemiComponent={RemiLeftArm}
                spring={leftArmSpring}
            />
            <RemiAnimationComponent
                RemiComponent={RemiCore}
                spring={coreSpring}
            />
            <RemiAnimationComponent
                RemiComponent={RemiThighs}
                spring={thighSpring}
            />
            <RemiAnimationComponent
                RemiComponent={RemiLegs}
                spring={legsSpring}
            />
        </div>
    );
}
